<template>
    <div :class="[{collapsed: collapsed}, 'sidebar', checkClasses]" :style="checkStyles" v-if="!isMobile" :ref="'sidebar' + obj.key" @transitionend="handleTransitionEnd">
      <template v-for="item in obj.children" :obj="item">
        <div v-if="item.type === 'LhImage'" class="image-wrapper">
          <component :is="item.type" :obj="item" :key="item.key"></component>
        </div>
        <component v-else :is="item.type" :obj="item" :key="item.key"></component>
      </template>
      <button :class="[{collapsed: collapsed}, 'button', 'sidebar-toggle', 'd-none', 'd-md-flex']" @click="handleToggle">
        <transition :name="rotateMode" mode="out-in">
          <font-awesome-icon
              v-if="!collapsed"
              class="sidebar-toggle-icon"
              :icon="['fal', 'angle-left']"
              :key="'icon-left'"
          ></font-awesome-icon>
          <font-awesome-icon
              v-else class="sidebar-toggle-icon"
              :icon="['fal', 'angle-right']"
              :key="'icon-right'"
          ></font-awesome-icon>
        </transition>
      </button>
    </div>
    <div :class="['sidebar-wrapper', {collapsed: collapsed}]" v-else>
      <div :class="[{collapsed: collapsed}, 'sidebar', checkClasses]" :style="checkStyles">
        <template v-for="item in obj.children" :obj="item">
          <component :is="item.type" :obj="item" :key="item.key"></component>
        </template>
        <button :class="[{collapsed: collapsed}, 'button', 'sidebar-toggle', 'd-none', 'd-md-flex']" @click="handleToggle">
          <transition :name="rotateMode" mode="out-in">
            <font-awesome-icon
                v-if="!collapsed"
                class="sidebar-toggle-icon"
                :icon="['fal', 'angle-left']"
                :key="'icon-left'"
            ></font-awesome-icon>
            <font-awesome-icon
                v-else class="sidebar-toggle-icon"
                :icon="['fal', 'angle-right']"
                :key="'icon-right'"
            ></font-awesome-icon>
          </transition>
        </button>
      </div>
    </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'sidebar',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      ...mapState({
        collapsed: 'sidebarCollapse',
        isMobile: 'isMobile'
      }),
      rotateMode () {
        return this.collapsed ? 'rotateLeft' : 'rotateRight'
      }
    },
    methods: {
      ...mapMutations({
        toggleSidebar: 'toggleSidebar'
      }),
      handleToggle () {
        this.$root.$emit('bv::hide::popover')
        this.toggleSidebar()
      },
      handleTransitionEnd (event) {
        let sidebar = this.$refs['sidebar' + this.obj.key]
        if ( event && event.target && sidebar.isSameNode(event.target)) {
          this.setSideBarWidth(sidebar)
        }
      },
      setSideBarWidth (element) {
        let sidebar = element ? element : this.$refs['sidebar' + this.obj.key]
        if (sidebar && sidebar.clientWidth !== null && sidebar.clientWidth !== undefined ) {
          this.$store.commit('setSidebarWidth', sidebar.clientWidth)
        }
      }
    },
    mounted () {
      this.$store.commit('setSidebar', this.obj)
      this.setSideBarWidth()
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  $gutter: $grid-gutter-width / 2;
  .sidebar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    z-index: 100;
    transition: transform ease-in-out 200ms;

    &.collapsed {
      transform: translateX(-100%);
    }
  }
  .sidebar {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    box-sizing: border-box;
    background: $sidebar-bg;
    color: $primary-color;
    padding: 1rem 0 100px 2rem;
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    box-shadow: 1px 0px 0px rgb(0 0 0 / 10%);

    @include media-breakpoint-up(md) {
      position: relative;
      min-width: $sidebar-max-width;
      width: $sidebar-max-width;
      max-width: $sidebar-max-width;
      overflow-x: initial;
      padding: 1rem 0 1.2rem 0;
      transition: width ease-in-out 150ms, min-width ease-in-out 150ms, max-width ease-in-out 150ms;
    }

    &-toggle {
      position: absolute;
      bottom: 0.85rem;
      right: 0;
    }

    &.collapsed {
      @include media-breakpoint-up(md) {
        max-width: $sidebar-min-width;
        min-width: $sidebar-min-width;
        width: $sidebar-min-width;
        padding: 2rem 5px 4rem 5px;
      }
    }

    p {
      overflow: visible;
      padding: 1em;
    }

    > img.image,
    > div > img.image {
      padding-right: 3rem;
      align-self: flex-start;

      @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
        height: auto;
        max-height: $sidebar-logo-max-height;
        max-width: 90%;
        margin: $sidebar-logo-margin;
      }
    }

    & > .image-wrapper {
      height: $sidebar-logo-wrapper-height;
      display: flex;
      align-items: center;
      justify-content: center;
      & > img {
        max-height: 100%;
      }
    }
  }

  .button-group {
    margin-top: 1rem;
    display: flex;

    .icon-button {
      color: $nav-active-link;
      font-size: 1.35rem;
      transition: color, 200ms;

      &:hover, &:focus {
        color: darken($nav-active-link, 10%);
      }
    }

    &.collapsed {
      width: 0;
      height: 0;
      opacity: 0;
      @include media-breakpoint-up(md) {
        width: $sidebar-min-width;
        height: auto;
        opacity: initial;
        flex-flow: column nowrap;
      }
    }
  }

  img.image {
    max-width: 100%;
    height: 60px;
    padding-bottom: 0 !important;
    padding-left: 0.7rem;
    padding-right: 0.7rem;

    .collapsed & {
      padding-left: 0;
      padding-right: 0;
    }

    @include media-breakpoint-up(md) {
      max-width: 100%;
      max-height: 90px;
    }
  }
</style>
