<template>
  <ValidationObserver :ref="'observer' + obj.key">
    <form slot-scope="{validate}"
          :ref="obj.key"
          @submit.prevent="validate().then(submit)"
          :style="checkStyles"
          :class="[checkClasses, 'lh-form']"
    >
      <template v-for="child in obj.children">
        <component :is="child.type" :obj="child" :key="child.key"></component>
      </template>

      <!-- checks response in mixinFunctions -->
      <template v-if="errorMessage">
        <component :is="errorMessage.type" :obj="errorMessage">{{errorMessage}}</component>
      </template>
    </form>
  </ValidationObserver>
</template>

<script>
  import { ValidationObserver } from 'vee-validate'
  import { eventBus } from '../services/event-bus'
  import isEmpty from 'lodash/isEmpty'
  import mixinFunctions from '@/mixin/mixinFunctions'
  import { mapState } from 'vuex'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'LhForm',
    mixins: [mixinFunctions, confirm],
    components: {
      ValidationObserver
    },
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        items: {},
        errorMessage: {
          attributes: {
            content: ''
          }
        },
        formIndex: 0,
        notifications: [],
        inputsKeys: [],
        initialValues: {}
      }
    },
    computed: {
      ...mapState({
        isMobile: 'isMobile'
      }),
      popup () {
        return this.$store.getters.isObjInsidePopup(this.obj.key)
      },
      formItems () {
        return this.$store.getters.getFormFilledDataByKey(this.obj.key)
      }
    },
    methods: {
      handleChange (event, value, inputName, inputKey, selectValue) {
        let inputs = this.formItems ? this.formItems.inputs : {}
        let oldValue = this.initialValues[inputKey]
        if (this.obj.attributes.type === 'filter') {
          // filter form -> in store
          let payload = {
            key: this.obj.attributes.target,
            inputs: {}
          }
          for (const key in inputs) {
            // check whether input has a value and is not the changed input
            if (inputs.hasOwnProperty(key) && key !== inputName) {
              if (this.checkValue(inputs[key])) {
                payload.inputs[key] = inputs[key]
              }
            }
          }
          // check if changed input has a value, if so - add it to payload
          if (selectValue && this.checkValue(selectValue)) {
            payload.inputs[inputName] = selectValue
          } else if (this.checkValue(value)) {
            payload.inputs[inputName] = value
          }
          this.$store.commit('setFilterDataByKey', payload)
        } else {
          // other forms
          // check if change was made
          let inputValue = (typeof value === 'object' && value !== null) ? JSON.stringify(value) : value
          let inputOldValue = (typeof oldValue === 'object' && oldValue !== null) ? JSON.stringify(oldValue) : oldValue
          if (this.popup && !this.popup.changed && inputValue !== inputOldValue  && value !== undefined && !this.obj.attributes.ignoreChanges) {
            if (event.hasOwnProperty('guid') && event.guid === oldValue) {
              return
            }
            let payload = {
              key: this.popup.key,
              data: {
                changed: true,
                formTab: undefined
              }
            }
            if (this.obj.attributes.targetTab) {
              payload.data.formTab = this.obj.attributes.targetTab
              payload.data.formTabKey = this.obj.key
            }
            this.$store.commit('setPopup', payload)
          }
        }
      },
      submitForm (options) {
        let hideToast = options.hideToast
        let action = this.obj.actions[0]
        let closeOnSave = this.obj.attributes.closeOnSave
        let closePopups = this.obj.attributes.closePopups
        if (options.button) {
          console.log('button! ', options.button)
          // get attributes from button if included
          action = options.button.actions[0]
          closeOnSave = options.button.attributes.closeOnSave
          closePopups = options.button.attributes.closePopups
          action.buttonAttr = options.button.attributes
        }
        if (this.$refs['observer' + this.obj.key]) {
          this.$refs['observer' + this.obj.key].validate().then(validated => {
            if (validated) {
              this.submit(action).then(result => {
                if (result) {
                  console.log('form submit result:', result)
                  let formError = undefined
                  if (result?.data?.formError) formError = result.data.formError
                  if (!formError) {
                    if (!hideToast) {
                      if (this.obj.attributes.customToast) {
                        this.$store.dispatch('setToast', this.obj.attributes.customToast)
                      } else {
                        const h = this.$createElement
                        let vNodesMsg = h('p', this.$t('GENERAL_SUBMIT_SUCCESS'))
                        let title = this.$t('GENERAL_SUBMIT_SUCCESS_TITLE')
                        this.$root.$bvToast.toast(vNodesMsg, {
                          title: title,
                          noAutoHide: false,
                          variant: 'success',
                          toaster: this.isMobile ? 'b-toaster-bottom-center' : 'b-toaster-top-right'
                        })
                      }
                    }

                    if (this.obj.attributes.clearOnSave) {
                      for (let inputKey of this.inputsKeys) {
                        eventBus.$emit('clearInput' + inputKey)
                      }
                    }

                    if (closeOnSave) {
                      this.$store.commit('closePopupOnSave', this.obj.key)
                    } else if (this.popup.changed) {
                      // reset changed to false after successful submit
                      let payload = {
                        key: this.popup.key,
                        data: {
                          changed: false
                        }
                      }
                      this.$store.commit('setPopup', payload)
                    }

                    if (closePopups) {
                      if (Array.isArray(closePopups)) {
                        closePopups.forEach(popup => {
                          this.$store.commit('closePopupOnSave', popup)
                        })
                      } else {
                        this.$store.commit('closeAllPopup')
                      }
                    }

                    if (options.tabIndex && this.obj.attributes.targetTab) {
                      eventBus.$emit('switch-tab', { index: options.tabIndex, targetTab: this.obj.attributes.targetTab })
                    }
                  }
                }
              })
            } else {
              this.notifications = Object.values(this.$refs['observer' + this.obj.key].ctx.errors).flat()
              eventBus.$emit('error-validation', this.notifications)
            }
          })
        }
      },
      processInputChange (data) {
        this.handleChange(data.event, data.value, data.name, data.key, data.selectValue)
      },
      collectInputKey (data) {
        this.inputsKeys.push(data.key)
        this.initialValues[data.key] = data.value
      },
      checkValue (val) {
        // check if input value is present or empty
        let valueFound = true
        if (typeof val === 'string') {
          valueFound = true
        }
        if (typeof val === 'object' || Array.isArray(val)) {
          if (isEmpty(val)) valueFound = false
        }
        if (val === undefined) valueFound = false
        if (val === null) valueFound = false

        return valueFound
      }
    },
    created () {
      let payload = {
        key: this.obj.key,
        inputs: {}
      }
      this.$store.commit('setFormFilledDataByKey', payload)
    },
    mounted () {
      eventBus.$on('submitForm' + this.obj.key, options => {
        this.submitForm(options)
      })
      eventBus.$on('inputEmit' + this.obj.key, this.processInputChange)
      eventBus.$on('initInput' + this.obj.key, this.collectInputKey)
    },
    beforeDestroy () {
      eventBus.$off('submitForm' + this.obj.key)
      eventBus.$off('inputEmit' + this.obj.key, this.processInputChange)
      eventBus.$off('initInput' + this.obj.key, this.collectInputKey)

      this.$store.commit('removeFormFilledData', this.obj.key)
    }
  }
</script>
