<!--LhWrappedSwitcher-->
<!--attributes-->
<!-- buttonType -> 'arrows' or 'more' -->
<!-- url to submit on button click -->
<!-- title -->

<template>
  <div class="lh-wrapper-switcher" :class="checkClasses" :style="checkStyles" v-tooltip="checkTooltip">
    <div class="lh-wrapper-switcher__actions">
      <h2>{{$t(obj.attributes.title)}}</h2>
      <div class="lh-wrapper-switcher__actions__wrapper">
        <span v-if="obj.attributes.buttonType == 'arrows'" class="lh-wrapper-switcher__actions__wrapper--arrows">
          <font-awesome-icon class="pressed-animation-bg" :icon="['fal', 'angle-left']"
                             @click="handleFetchContent('LEFT')"></font-awesome-icon>
          <font-awesome-icon class="pressed-animation-bg" :icon="['fal', 'angle-right']"
                             @click="handleFetchContent('RIGHT')"></font-awesome-icon>
        </span>
        <span v-else-if="obj.attributes.buttonType == 'more'">
          <font-awesome-icon class="pressed-animation-bg" :icon="['fas', 'list-ul']"
                             @click="handleFetchContent('MORE')"></font-awesome-icon>
          <span>{{$t('GENERAL_MORE')}}</span>
        </span>
      </div>
    </div>
    <div class="lh-wrapper-switcher__content">
      <template v-if="content[page] && content[page].length > 0" >
        <component v-for="child in content[page]" :is="child.type" :obj="child"></component>
      </template>
      <p v-else>{{$t('GENERAL_NO_CONTENT')}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LhWrappedSwitcher',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        page: -1,
        content: {},
      }
    },
    methods: {
      handleFetchContent (direction) {
        let changedPage = this.page
        switch(direction) {
          case 'LEFT':
            changedPage--
            break;
          case 'RIGHT':
            changedPage++
            break;
          case 'MORE':
            changedPage++
            break;
        }

        if (this.content[changedPage]) {
          this.page = changedPage
        } else {
          // let url = this.obj.attributes.url + '/' + changedPage

          //test url without param
          let url = this.obj.attributes.url
          this.$store.dispatch('fetchJSON', url).then(response => {
            if (response && response.data && response.data.page) {
              this.$set(this.content, changedPage, response.data.page.children)
              this.page = changedPage
            } else if (response && response.data) {
              this.$set(this.content, changedPage, response.data.page)
              this.page = changedPage
            }
            // TODO else failed toast
          })
        }
      }
    },
    mounted () {
      this.$set(this.content, this.page, this.obj.children)
    }
  }
</script>

<style lang="scss">
  .lh-wrapper-switcher {
    &__actions {
      display: flex;
      justify-content: space-between;

      &__wrapper {
        font-weight: 700;

        svg {
          width: 25px !important;
          margin: 0 10px;
          cursor: pointer;
          font-size: $icon-font-size;
        }

        &--arrows {
          svg {
            background-color: $medium-grey;
            border-radius: 25px;
            color: $white;
            font-size: $icon-font-size;
          }
        }
      }
    }

    &__content {
      border: 1px solid $light-grey;
      padding: 0 20px 20px;
      //TODO FOR DEMO PURPOSE. REMOVE max-width
      max-width: 70vw;
    }
  }
</style>

