<template>
  <div class="gridComplex" :style="checkStyles" :class="[checkClasses, {'checkbox-visible': (checkboxVisible && !obj.attributes.disableMultiSelect) || selectMode}]">
    <div :class="['table-wrapper', {isMobile: isMobile}]">
      <div class="checkbox-wrapper select-all" :class="[{'checkbox-mobile': isMobile}]">
        <b-form-checkbox
            v-if="(isMobile && checkboxMobile && !obj.attributes.disableMultiSelect)"
            class="chkbox"
            v-model="allSelected"
            :indeterminate="indeterminate"
            @change="toggleAll"
        ></b-form-checkbox>
      </div>
      <div class="gridComplex-no-results" v-if="!hasResults && !disableNoResults">
        <p>{{$t('GENERAL_NO_RESULTS')}}</p>
      </div>
      <table v-if="hasResults || disableNoResults" class="grid-table noselect">
        <!-- table header -->
        <template>
          <lh-grid-t-head
              v-if="!isMobile"
              :headers="headersStore"
              @headerClick="handleHeaderClick"
              :indeterminate="indeterminate"
              :allSelected="allSelected"
              :checkboxVisible="checkboxVisible"
              :selectMode="selectMode"
              :checkboxMobile="checkboxMobile"
              :disableMultiSelect="obj.attributes.disableMultiSelect"
              @toggleAll="toggleAll"
          ></lh-grid-t-head>
          <!-- table body -->
          <template v-for="(row, index) in obj.children" class="row-wrapper">
            <component
                v-if="row.children[0] && row.children[0].type !== 'LhGridHeader'"
                :is="row.type"
                :obj="row"
                :browseKey="obj.key"
                :index="index"
                :key="row.key"
                :headersWidth="headersWidth"
                :rowSelectedChk="selectedRowCheck(row)"
                :rowChecked="checkedRowsCheck(row, index)"
                :showCheckbox="checkboxMobile"
                :checkboxVisible="checkboxVisible"
                :selectMode="selectMode"
                :scrollOnExpand="obj.attributes.scrollOnExpand"
                @rowClick="handleRowClick"
                @rowChecked="handleRowCheck($event)"
                @input-click="clickedRow = row.attributes.guid"
                v-longpress="handleRowLongPress"
                :disableMultiSelect="obj.attributes.disableMultiSelect"
                :class="{index: clickedRow === row.attributes.guid}"
            ></component>
          </template>
        </template>
      </table>
      <div v-if="obj.attributes.loadMore && showLoadMore" class="grid-load-more">
        <lh-button :obj="loadMoreButton" class="mt-3" @click.native="loadMoreData()"></lh-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex'

  export default {
    name: 'GridComplex',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {
        pressTimer: null,
        selectedRow: {},
        activeRows: [],
        checkedRows: [],
        browseSetting: {},
        headers: [],
        expandItems: {},
        allowMultipleExpansions: false,
        headersWidth: {},
        allSelected: false,
        indeterminate: false,
        checkboxVisible: false,
        checkboxMobile: false,
        deselecting: false,
        detectDoubleClick: false,
        selectMode: false,
        clickedRow: '',
        nextPage: 2,
        showLoadMore: true,
        loadMoreButton: {
          'key': 'browse-load-more',
          'type': 'LhButton',
          'actions': [],
          'attributes': {
            'content': 'GENERAL_LOAD_MORE',
            'name': 'browse-load-more',
            'class': 'btn-load-more secondary'
          },
        }
      }
    },
    computed: {
      ...mapGetters({
        browses: 'getBrowseSettings',
      }),
      ...mapState({
        isMobile: 'isMobile'
      }),
      disableNoResults(){
        return this?.obj?.attributes?.disableNoResultsRow ? true : false
      },
      hasResults(){
        let result = false
        if(this?.obj?.children && this.obj.children.length > 0){
          //Has first row
          let hasHeaders = (this?.obj?.children[0]?.children.filter(row => row.type === 'LhGridHeader').length > 0) ? true : false
          let hasSecondRow = (this?.obj?.children[1]) ? true : false
          if(!hasHeaders || (hasHeaders && hasSecondRow)){
            result = true
          }
        }
        return result
      },
      browseSettingStore () {
        let setting = this.$store.getters.getBrowseSettingByKey(this.obj.key)
        return setting ? setting : null
      },
      headersStore: {
        get () {
          return this.$store.getters.getBrowseSettingByKey(this.obj.key)
              ? this.$store.getters.getBrowseSettingByKey(this.obj.key).headers
              : null
        },
        set (val) {
          this.headers = val
        }
      },
      rows () {
        let rows = []
        this.obj.children.forEach((child, index) => {
          if (child.type.toLowerCase() === 'lhgridrow' && child.children[0].type.toLowerCase() !== 'lhgridheader') {
            rows.push(this.browseSetting.key + index)
          }
        })
        return rows
      }
    },
    methods: {
      ...mapActions({
        post: 'postLocal'
      }),
      selectedRowCheck: function (row) {
        return (this.selectedRow.key === row.key || this.activeRows[row.key] ? true : false)
      },
      checkedRowsCheck: function (row, index) {
        return (this.activeRows[row.key] ? true : false || this.checkedRows.some(item => item == this.browseSetting.key + index))
      },
      cancelTimer: function () {
        // Check if longpress timer has a value or not
        if (this.pressTimer !== null) {
          clearTimeout(this.pressTimer)
          this.pressTimer = null
        }
      },
      handleRowClick: function (event, row, index, checked) {
        if (!this.obj.attributes.disableMultiSelect && event.shiftKey && this.checkedRows.length > 0) {
          let firstItemIndex = this.checkedRows[0].match(/\d+/g).map(Number)
          let lastItemIndex = index
          console.log('shift detected', firstItemIndex, index)
          this.checkedRows = []
          // if index same -> do nothing
          if (lastItemIndex > firstItemIndex) {
            for (let i = firstItemIndex; i <= lastItemIndex; i++) {
              this.highlightRow(event, i)
            }
          } else if (lastItemIndex < firstItemIndex) {
            for (let i = lastItemIndex; i <= firstItemIndex; i++) {
              this.highlightRow(event, i)
            }
          }
        } else if (!this.obj.attributes.disableMultiSelect && event.ctrlKey) {
          console.log('ctrl detected', index)
          let isCheckedIndex = null
          for (let j in this.checkedRows) {
            if (this.checkedRows[j] == this.obj.key + index) {
              isCheckedIndex = j
            }
          }
          if (isCheckedIndex !== null) {
            this.checkedRows.splice(isCheckedIndex, 1)
          } else {
            this.highlightRow(event, index)
          }
        } else {
          if(!this.allowMultipleExpansions) {
            this.activeRows = []
          }
          if (!this.selectMode) {
            this.checkedRows = []
            this.highlightRow(event, index)
            if (!this.isMobile) {
              this.checkboxVisible = true
            }
          }
          //check for double click or single click
          setTimeout(() => {
            console.log('handling single click')
            if (event.detail === 1 && !this.detectDoubleClick) {
              if (!this.selectMode) {
                if (this.selectedRow.key !== row.key && !this.activeRows[row.key]) {
                  this.selectedRow = row
                } else {
                  this.selectedRow = {}
                  let index = this.checkedRows.indexOf(this.browseSetting.key + row.index)
                  this.checkedRows.splice(index, 1)
                }
                if(this.activeRows[row.key]) {
                  delete this.activeRows[row.key]
                } else if(this.selectedRow.key === row.key) {
                  this.activeRows[row.key] = row
                }
              }
              if (this.selectMode) {
                this.handleRowCheck({
                  value: checked,
                  index: index
                })
              }
            }
            this.detectDoubleClick = false
          }, 400);

          if (event.detail === 2) {
            //close expand if other row is selected
            if (this.selectedRow.key !== row.key) {
              this.selectedRow = {}
            }

            this.detectDoubleClick = true
            console.log("handling double click")
          }
        }
      },
      handleHeaderClick (key) {
        console.log('sorting column: ' + key)
        let header = this.headers.find(head => head.key === key)
        if (header) {
          console.dir(header, key)
          header.sort === 2 ? (header.sort = 0) : header.sort++
          header.sorting = true

          //reset all others
          for (const header of this.headers) {
            if (header.key !== key) {
              header.sort = 0
              header.sorting = false
            }
          }
          this.$store
              .dispatch('sortAndFilter', {
                target: this.obj.key,
                url: this.obj.attributes.url,
                key: key,
                sort: header.sort,
                browseKey: this.obj.key
              })
        }
      },
      highlightRow (event, index) {
        //hidden checkbox en row
        this.checkedRows.push(this.browseSetting.key + index)
      },
      updateSelectedRows () {
        let selectedRows = []
        for (let check of this.checkedRows) {
          let index = check.match(/\d+/g).map(Number)
          selectedRows.push(this.obj.children[index].attributes.guid)
        }
        this.$store.dispatch('setBrowseSettingByKey', {
          key: this.obj.key,
          payload: {
            selectedRows: selectedRows,
            selectionLength: this.checkedRows.length
          }
        })
      },
      findHeaders () {
        let headersArr = []
        if (this.obj.children[0]) {
          let index = 0
          //first child -> headers
          for (const child of this.obj.children[0].children) {
            index++

            headersArr.push({
              name: child.attributes.content,
              checked: child.attributes.hasOwnProperty('checked')
                ? child.attributes.checked === 'false' || child.attributes.checked === false ? false : true
                : true,
              key: child.key,
              type: child.attributes.cellType,
              sort: child.attributes.sort ? child.attributes.sort : 0,
              sorting: false,
              showMobile: child.attributes.showMobile,
              disableSort: child.attributes.disableSort,
              hidden: this.obj.attributes.hideHeaders,
              subContent: child.attributes.subContent
            })
          }
        }
        this.headers = headersArr
        if(this.browseSetting){
          this.browseSetting.headers = this.headers
        }
      },
      setHeadersFromStore (reset) {
        if (this.browseSettingStore && !reset) {
          this.headers = this.browseSettingStore.headers
        } else {
          this.findHeaders()
        }
      },
      getSettings () {
        this.$store.dispatch('getTableSettings')
      },
      hideExpandRows () {
        for (const row of this.obj.children) {
          if (row.attributes.expand) {
            this.expandItems[row.key] = {
              target: row.attributes.target,
              show: false
            }
          } else {
            this.expandItems[row.key] = {
              show: true
            }
          }
        }
      },
      calcHeaderWidth (headerWidth) {
        this.headersWidth[headerWidth.key] = headerWidth.width
      },
      toggleAll (checked) {
        this.checkedRows = checked ? this.rows.slice() : []
        this.checkboxMobile = checked

        if(this.checkedRows.length === 0){
          this.selectedRow = {}
          this.activeRows = []
        }
      },
      setCheckboxStates (newVal, oldVal) {
        if (newVal.length === 0) {
          this.indeterminate = false
          this.allSelected = false
          this.selectedRow = {}
          setTimeout(() => {
            if (this.checkedRows.length === 0) {
              this.checkboxVisible = false
            }
          }, 1000)
        } else if (newVal.length === this.rows.length) {
          this.indeterminate = false
          this.allSelected = true
        } else {
          this.indeterminate = true
          this.allSelected = false
        }
      },
      handleRowLongPress () {
        this.checkboxMobile = !this.checkboxMobile
        if (!this.checkboxMobile) {
          this.checkboxVisible = false
        }
      },
      handleRowCheck (data) {
        if (data.value) {
          let index = this.checkedRows.indexOf(this.browseSetting.key + data.index)
          this.checkedRows.splice(index, 1)
        } else {
          if (!this.checkedRows.some(chk => chk == this.browseSetting.key + data.index)) {
            this.checkedRows.push(this.browseSetting.key + data.index)
          }
        }
        this.setCheckboxStates(this.checkedRows)
      },
      addActiveFilters () {
        let filter = this.obj.attributes.activeFilters
        let payload = {
          key: this.obj.key,
          defaultFilters: filter,
          inputs: {}
        }
        if (filter) {
          this.$store.commit('setFilterDataByKey', payload)
        }
      },
      checkExpandRow (row) {

      },
      loadMoreData() {
        let activeFiltersAndSorting = this.$store.getters.getFilterSortPageData(this.obj.key)
        activeFiltersAndSorting.page = this.nextPage
        let data = {
          url: this.obj.attributes.loadMore,
          payload: activeFiltersAndSorting
        }

        console.log('browse: load more (p' + (this.nextPage) + ')')
        this.post(data).then((response) => {
          console.log('Load More Response:', response)
          if (response.data.length > 0 && response.data[0].length > 0) {
            //Add rows to the grid
            this.$set(this.obj, 'children', this.obj.children.concat(response.data[0]))
          }
          if (typeof response.data[2] != 'undefined' && response.data[2].loadMore != '' && response.data[2].loadMore) {
            //Update loadMore url
            this.$set(this.obj.attributes, 'loadMore', response.data[2].loadMore)
            this.nextPage = parseInt(response.data[2].loadMore.substring(response.data[2].loadMore.lastIndexOf('/') + 1), 10)
          }
          if (typeof response.data[1] != 'undefined') {
            //Update stats
            let componentKey = response.data[1].key
            let payload = {
              target: componentKey,
              content: response.data[1].attributes,
              popup: this.$store.getters.isObjInsidePopup(componentKey),
              type: 'attributes'
            }
            this.$store.commit('replaceTarget', payload)
          }
          if (typeof response.data[2] != 'undefined' && !response.data[2].showLoadMore) {
            //Hide loadMore
            this.showLoadMore = false
          }
        })
      },
      initSelectedRows () {
        if (this.obj.attributes.initialSelection.length > 0) {
          this.obj.attributes.initialSelection.forEach(row => {
            let index = this.obj.children.findIndex(item => item.key === row)
            this.handleRowCheck({index: index})
          })
        }
      }
    },
    watch: {
      checkedRows (newVal, oldVal) {
        // Handle changes in individual row checkboxes
        if (!this.deselecting) {
          this.setCheckboxStates(newVal, oldVal)
          this.updateSelectedRows()
        } else {
          this.deselecting = false
        }

      },
      obj () {
        //Reset nextPage
        this.nextPage = 2
        this.setCheckboxStates(this.checkedRows)
        //Reset headers
        this.setHeadersFromStore(true)
      },
      'browseSetting.selectedRows' (val) {
        if ( val && val.length == 0) {
          this.deselecting = true // prevent infinite loop of 2 watchers
          this.checkedRows = []
        }
      }
    },
    created () {
      this.setHeadersFromStore()
      if (this.obj.attributes.selectMode) this.selectMode = true
      if (this.obj.attributes.allowMultipleExpansions) this.allowMultipleExpansions = true

      this.browseSetting = {
        //content: this.obj,
        headers: this.headers,
        key: this.obj.key
      }
      this.$store.commit('addBrowseSetting', this.browseSetting)

      if (this.obj.attributes.initialSelection) this.initSelectedRows()

      this.hideExpandRows()
      this.addActiveFilters()
    },
    directives: {
      longpress: {
        bind: function (el, binding, vnode) {
          let _this = vnode.context
          if (_this.isMobile) {
            // Define function handlers
            // Create timeout ( run function after 1s )
            let start = e => {
              if (e.type === 'mousedown') {
                return
              }
              if (_this.pressTimer === null) {
                _this.pressTimer = setTimeout(() => {
                  // Run function
                  handler()
                }, 800)
              }
            }

            // Cancel Timeout
            let cancel = _this.cancelTimer
            // Run Function
            const handler = e => {
              binding.value(e)
            }

            // Add Event listeners
            el.addEventListener('mousedown', start)
            el.addEventListener('touchstart', start)
            // Cancel timeouts if this events happen
            el.addEventListener('click', cancel)
            el.addEventListener('mouseout', cancel)
            el.addEventListener('touchend', cancel)
            el.addEventListener('touchcancel', cancel)
            el.addEventListener('mousemove', cancel)
            window.addEventListener('scroll', cancel)

            //set id to later remove element
            el.setAttribute('id', 'eventElement' + _this.obj.key)
          }
        }
      }
    },
    beforeDestroy () {
      //clone element to remove eventlisteners
      var old_element = document.getElementById('eventElement' + this.obj.key)
      if (old_element) {
        var new_element = old_element.cloneNode(true)
        // TODO: turn on next line for production.
        // old_element.parentNode.replaceChild(new_element, old_element)
      }

      window.removeEventListener('scroll', this.cancelTimer)

      this.$store.commit('removeBrowseSetting', this.obj.key)
    }
  }
</script>

<style lang="scss">
  .gridComplex {
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    /*overflow-x: auto;*/
    transition: all 1s;
    width: 98vw;
    padding-top: 1rem;
    position: sticky;
    height: calc(100vh - 230px);
  }

  .gridComplex-no-results {
    background: $ultra-light-grey;
    color: $medium-dark-grey;
    padding: 1rem;
    text-align: center;
    p {
      margin: 0;
    }
  }

  .popup-content .gridComplex {
    height: auto;
  }

  .grid-table {
    flex: 1 1 auto;
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    overflow-x: visible;
  }

  .grid-load-more {
    flex: 1 1 auto;
    text-align: center;
  }

  .table-wrapper {
    overflow-x: auto;
    width: 100%;
  }

  .title {
    /*background-color: burlywood;*/
    width: 80%;
    margin: 0 auto;
  }

  tbody {
    border-top: 1px solid $white;
    min-height: 40px;
  }

  tbody:nth-of-type(odd):not(.row_selected) {
    background-color: $ultra-light-grey;
  }

  .gridrowItem:hover {
    background-color: $light-grey;
  }

  .row_selected {
    > tr:first-of-type, &.grid-card-item-content {
      background-color: $browse-selected-color;
      color: $white;
    }
  }

  .checkbox-wrapper {
    background-color: $background;
    width: 2.5rem;
    position: relative;
    top: -1px;

    .custom-control {
      padding-left: 2rem;
    }

    .popup-content & {
      padding-left: 0.7rem;
    }

    &.checkbox-mobile {
      position: absolute;
      left: 1em;
      top: -15px;
      z-index: 10;

      .custom-control-label::before, .custom-control-label::after {
        width: 1.5rem;
        height: 1.5rem;
      }

      &.select-all {
        left: 9px;
      }
    }

    &.select-all {
      margin-top: 0;
    }
  }

  .popup-content {
    .table-wrapper {
      &:not(.isMobile) {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .table-wrapper {
    .expand-row {
      .grid-lite {
        .gridHeader {
          padding: 1em 1em 0em 1em;
        }
      }
    }
  }
</style>
