import moment from 'moment'
import { eventBus } from '../services/event-bus'

export default {
  methods: {
    // ----------- events --------------
    allEventHandler (action) {
      let _this = this
      return function (e) {
        _this.switchEvent(action, e)
      }
    },
    switchEvent (action, e) {
      if (action != null) {
        var myFunction = action.type
        console.log(myFunction + ':' + action.method + ' ' + action.type + ' ' + action.url)
        //confirm prompt
        if (action.confirmation !== undefined && action.confirmation !== null) {
          this.confirm(action.confirmation).then(value => {
            if (value) {
              this[myFunction](action)
            }
          })
        } else {
          this[myFunction](action)
        }
        if (!action.propagation && e && e.target) {
          //check if e is event
          e.stopPropagation()
        }
      }
    },
    emitAllEvents (action) {
      console.log(action.event, this.innerValue)
      // this.$emit(event, val)
    },
    emit (action) {
      eventBus.$emit(action.method, action)
      console.log('emitting ', action.url)
    },
    // --------- tools ---------
    findTarget (obj, targetString) {
      let foundElement = {}
      let elementPath = []
      let findTargetLoop = function (element, target) {
        if (element.key === target) {
          foundElement = element
        } else if (element.children) {
          for (let [index, child] of element.children.entries()) {
            if (!foundElement) {
              // this.elementPath.push(".children[" + index + "]");
              elementPath.push(index)
              findTargetLoop(child, target)
              if (!foundElement) {
                elementPath.splice(-1, 1)
              }
            }
          }
        }
        return { target: foundElement, path: elementPath }
      }
      return findTargetLoop(obj, targetString)
    },
    getDateTime () {
      //format YYYY-MM-DDTHH:mm:ss
      return moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
    },

    // --------- actions -----------
    forceFileDownload (action) {
      let payload = {
        url: action.url,
        name: this.obj.attributes.fileName
      }
      this.$store.dispatch('download', payload)
    },
    submit (action) {
      console.log(this.obj.attributes.type)
      if (this.obj.attributes.type === 'export') {
        let payload = this.getSubmitForm(action)
        let data = {
          payload: payload,
          target: this.obj.attributes.target,
          url: action.url,
          browseKey: this.obj.attributes.target,
          pressedBtn: this.obj
        }
        return this.$store.dispatch('export', data)
      }
      // else if (this.obj.attributes.multiSelectOnly) {
      //   this.multiSelectSubmit(action)
      // }
      else if (this.obj.attributes.target && !this.obj.attributes.multiSelectOnly && !this.obj.attributes.selectionOnly && !this.obj.attributes.singleSelectionOnly && !this.obj.attributes.includeSelectedRows) {
        this.getSubmitFilterAndSort(action)
      } else {
        let payload = this.getSubmitForm(action)
        if (payload) {
          return this.$store.dispatch('submit', payload).then(response => {
            if (response) {
              if (response.errorMessage) {
                this.errorMessage = response.errorMessage
              } else if (response.key == 'error-message') {
                this.errorMessage = response
              }
            }
            return response
          })
        }
      }
    },
    getSubmitForm (action) {
      if (!action) {
        action = this.obj.actions[0]
      }

      let formData = {
        formName: this.obj.key,
      }

      if (this.obj.attributes.value) formData.formValue = this.obj.attributes.value
      //if has attribute freeOrder -> submit free order
      if (this.obj.attributes.freeOrder) {
        eventBus.$emit('submitForm' + this.obj.attributes.freeOrder, { button: this.obj })
        return null
      }
      //if has attribute form -> execute submit form
      if (this.obj.attributes.form) {
        eventBus.$emit('submitForm' + this.obj.attributes.form, { button: this.obj })
        return null
      }

      // // send btn value
      if (['LhButton', 'lh-button', 'LhIconButton', 'lh-icon-button'].includes(this.obj.type)) {
        formData.btnValue = this.obj.attributes.value
      }

      let items = this.formItems ? this.formItems.inputs : {}

      let checkIsObject = function (obj) {
        if(typeof obj === 'object' && !Array.isArray(obj) && !(typeof obj.name == 'string') && !(typeof obj.size == 'number')){
          return true;
        }
        return false;
      }

      for (const input in items) {
        if (input != undefined && items[input] != undefined && items[input] !== null) {
          if (items[input].hasOwnProperty('guid')) {
            // autosuggest guid value
            formData[input] = items[input].guid
          } else if (items[input].hasOwnProperty('value') ) {
            // select value
            formData[input] = items[input].value
          } else if (checkIsObject(items[input])) {
            // object values
            formData[input] = JSON.stringify(items[input])
          } else {
            // binary files & string values
            formData[input] = items[input]
          }
        }
      }

      formData.submitDate = this.getDateTime()

      let payload = {
        formData: formData,
        action: action,
        actionKey: this.obj.key,
        closeOnSave: this.obj.attributes.closeOnSave,
        obj: this.obj
      }
      if (this.obj.attributes.includeTableInfo) {
        payload.tableInfo = this.$store.getters.getFilterSortPageData(this.obj.attributes.includeTableInfo)
      }

      return payload
    },
    getSubmitFilterAndSort (action) {
      this.$store.dispatch('sortAndFilter', {
        target: this.obj.attributes.target,
        url: action.url,
        browseKey: this.obj.attributes.target,
        pressedBtn: this.obj
      }).then(result => {

      })
    },
    update (action) {
      this.display(action)
    },
    display (action) {
      if (action) {
        //open popup

        let formdata = {}
        if (this.obj.attributes.value) formdata.value = this.obj.attributes.value

        let payload = {
          action: action,
          formdata: formdata
        }

        if (this.obj.attributes.includeTableInfo) {
          payload.tableInfo = this.$store.getters.getFilterSortPageData(this.obj.attributes.includeTableInfo)
        }

        this.$store.dispatch('retrieveContent', payload).then(response => {
          let viewType = this.$store.getters.getViewType
          if (response && response.key) {
            if (viewType === 'POPUP') {
              let payloadPopup = {
                content: response,
                isOpen: true,
                key: response.key
              }
              this.$store.commit('addPopup', payloadPopup)
            } else if (viewType === 'FULLPAGE') {
              let payload = {
                action: action,
                formdata: formdata
              }
              this.$store.dispatch('retrieveContent', payload).then(response => {
                this.$store.commit('setDetailPage', response)
              })
              this.$router.push({ name: 'detailPage' })
            } else if (viewType === 'SIDEFORM') {
              let payload = {
                action: action
              }
              this.$store.dispatch('retrieveContent', payload).then(response => {
                this.$store.commit('setSideFormContent', response)
              })
            }
          }
          if (response[0].type.toLowerCase() === 'lhplanner') {
            eventBus.$emit('update-planner', response[0])
          }
        })
      }
    },
    export (action) {
      let payload = {
        action: action,
        pressedBtn: this.obj
      }
      this.$store.dispatch('export', payload)
    },
    inputUpdate (action) {
      let name = this.obj.attributes.name
      let payload = {
        action: action,
        obj: this.obj,
        formData: { [name]: this.innerValue }
      }
      this.$store.dispatch('submit', payload)
    },
    redirect (action) {
      if (this.$route.path !== action.url) {
        this.$store.commit('setRedirectCheck', true)
        this.$store.commit('setRedirectCheckApi', true)
        this.$router.push({path: action.url, params: {'0': action}})
      } else {
        this.$store.dispatch('fetchJSON', this.$route.fullPath)
      }
    },
    multiSelectSubmit (action) {
      let payload = {
        key: this.obj.key,
        action: action,
        target: this.obj.attributes.target,
        btn: this.obj
      }

      this.$store.dispatch('submitMultiSelect', payload)
    },
    checkActions (actions) {
      for (const action of actions) {
        //some components needs to emit events to LhInput or LhForm
        if (this.emitEvent) {
          this.allEvents[action.event] = this.emitAllEvents(action)
        } else {
          this.allEvents[action.event] = this.allEventHandler(action)
        }
      }
    }
  },
  data () {
    return {
      allEvents: {},
    }
  },
  created () {
    if (this.obj.actions) {
      this.checkActions(this.obj.actions)
    }
  },
  watch: {
    'obj.actions': function (val) {
      if (val) {
        this.checkActions(val)
      }
    }
  },
}
