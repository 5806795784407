<template>
  <td :class="['gridCell', checkClasses]" :style="checkStyles" v-if="hasHeader">
    <template v-if="obj.children.length !== 0">
      <component v-for="(item, index) in obj.children"
                 :is="item.type"
                 :obj="item"
                 :key="item.key"
                 @input-click="$emit('input-click')"
      ></component>
    </template>
    <template v-else>
      <template v-if="obj.attributes.content">
        {{ $t(obj.attributes.content) }}
      </template>
      <template v-else>
<!--        else leave empty-->
        &nbsp;
      </template>
    </template>
  </td>
</template>


<script>

  export default {
    name: 'GridCell',
    components: {},
    props: {
      obj: {
        type: Object
      },
      browseKey: {
        type: String
      }
    },
    computed: {
      headers () {
        let setting = this.$store.getters.getBrowseSettingByKey(this.browseKey)
        return setting ? setting.headers : null
      },
      hasHeader () {
        let child = this.obj
        let setting = this.$store.getters.getBrowseSettingByKey(this.browseKey)
        if (setting && setting.headers) {
          return setting.headers.find(header => header.key === child.attributes.target && header.checked) ? true : false
        }
        return false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .gridCell {
    padding: 0 1em;
    text-align: left;
    white-space: nowrap;

    &.toggleIcon {
      padding: 0;
      color: $medium-grey;
      vertical-align: center;

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
        width: 7px;
      }

      .row_selected & {
        color: $white;
      }
    }

    &:nth-of-type(2) {
      padding-left: 0;
    }

    .icon-button.icon-button {
      font-size: 14px;
      display: inline-flex;
      padding: 7px;
      background: $browse-edit-buttons-bg;
      color: $browse-edit-buttons-color;
      margin: 5px 5px 5px 0;
      border-radius: 50%;
      transition: background-color 200ms;

      &:hover, &:focus {
        background: darken($browse-edit-buttons-bg, 10%);
      }

      &.btn-delete {
        &:hover, &:focus {
          background: $delete-red;
        }
      }

      &.icon-only {
          background: none !important;
      }
    }

    .button-wrapper {
      padding-bottom: 0;
    }

    &:last-of-type {
      text-align: right;
    }

    &.cell-sticky {
      position: sticky;
      right: -20px;
      min-width: 80px;
      padding: 0;
      width: 100%;
    }
  }

  .icon-button.icon-button {
    .row_selected & {
      color: $white;
    }
  }

  .grid-lite {
    .gridCell {
      &:nth-of-type(2) {
        padding-left: 1em;
      }
    }
  }
</style>
