<template>
  <div :class="['button-wrapper', obj.attributes.wrapperClass]" >
    <button
        :class="[{transparent: obj.attributes.transparent}, {disabled: checkDisabled},
         'button', checkClasses]"
        v-on="allEvents"
        @click="checkForModal"
        :value="checkEmpty(obj.attributes.value)"
        :style="checkEmpty(obj.styles)"
        :disabled="checkDisabled"
        :type="checkEmpty(obj.attributes.type)"
        :form="checkEmpty(obj.attributes.form)"
    >
      <span class="button__icon">
        <font-awesome-icon v-if="checkEmpty(obj.attributes.icon)"
                           :icon="obj.attributes.icon"></font-awesome-icon>
      </span>
      {{$t(obj.attributes.content)}}
      <lh-edit-translation :obj="{key: obj.attributes.content, parentKey: obj.key}"></lh-edit-translation>
    </button>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import mixinFunctions from '@/mixin/mixinFunctions'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'LhButton',
    mixins: [ mixinFunctions, confirm ],
    props: {
      obj: {
        type: Object
      },
      modalId: {
        type: String
      }
    },
    computed: {
      ...mapGetters({
        getBrowseSettingByKey: 'getBrowseSettingByKey'
      })
    },
    methods: {
      checkForModal () {
        if (this.modalId) {
          this.$root.$emit('bv::hide::modal', this.modalId)
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  // global button style in style/global/_button.scss
  .button-wrapper {
    padding-bottom: 1rem;
    display: inline-flex;

    &.w-100 {
      button.button {
        width: 100% !important;
        //justify-content: flex-start;
      }
    }
  }
</style>
