<template>
  <div class="LhDiv" :class="checkClasses" :style="checkStyles" v-tooltip="checkTooltip">
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
  </div>
</template>

<script>
  export default {
    name: 'LhDiv',
    props: {
      obj: {
        type: Object
      }
    }
  }
</script>