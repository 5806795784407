import { findTarget } from '../js/find-target'

const getters = {
  loggedIn (state) {
    return state.token !== null
  },
  getToken: state => state.token,
  getDownloading: state => state.downloading,
  getPageLoaded: state => {
    return state.pageLoaded
  },
  getLoadingOrError: state => state.loadingOrError,
  getMainJSON: state => {
    return state.mainJSON
  },
  getPopupSettings: state => {
    return state.popupSettings
  },
  getPopups: state => {
    return state.popups
  },
  getFormItems: state => {
    return state.formItems
  },
  getBrowseSettings: state => {
    return state.browseSettings
  },
  getBrowseSettingByKey: state => key => {
    return state.browseSettings.find(item => item.key === key)
  },
  getFilterData: state => {
    return state.filterData
  },
  getFilterDataByKey: state => key => {
    return state.filterData.find(item => item.key === key)
  },
  getPaginationData: state => {
    return state.paginationData
  },
  getPaginationByKey: state => key => {
    return state.paginationData.find(item => item.key === key)
  },
  getFormFilledDataByKey: state => key => {
    return state.formFilledData.find(item => item.key === key)
  },
  getViewType: state => {
    return state.viewType
  },
  getSideFormContent: state => {
    return state.sideFormContent
  },
  getDetailPage: state => {
    return state.detailPage
  },
  getHtmlPage: state => {
    return state.htmlPage
  },
  getFilterSortPageData: state => key => {
    let filterData = state.filterData.find(item => item.key === key)
    let pagination = state.paginationData.find(item => item.key === key)
    let tableSetting = state.browseSettings.find(item => item.key === key)
    let header = tableSetting
        ? tableSetting.headers.find(item => item.sorting ? item.sorting : (item.sort == 0 ? false : true))
        : null
    let sort = {}

    header ? (sort[header.key] = header.sort) : null
    // sort[header.key] = header.sort;

    let headers = tableSetting && tableSetting.headers
        ? tableSetting.headers.filter(item => item.checked)
        : null

    //merge both type filters

    let allFilters = {}
    if (filterData && filterData.inputs) {
      for (let inputKey in filterData.inputs) {
        allFilters[inputKey] = filterData.inputs[inputKey]
        if (allFilters[inputKey] && allFilters[inputKey].hasOwnProperty('text') && allFilters[inputKey].hasOwnProperty('value')) {
          allFilters[inputKey] = allFilters[inputKey].value
        }
      }
    }
    if (filterData && filterData.defaultFilters) {
      for (let inputKey in filterData.defaultFilters) {
        allFilters[inputKey] = filterData.defaultFilters[inputKey]
        if (allFilters[inputKey] && allFilters[inputKey].hasOwnProperty('text') && allFilters[inputKey].hasOwnProperty('value')) {
          allFilters[inputKey] = allFilters[inputKey].value
        }
      }
    }

    console.log(allFilters, filterData)
    let payload = {
      currentPage: pagination ? pagination.currentPage : null,
      browseSize: pagination ? pagination.browseSize : 10,
      filter: allFilters,
      sort: sort ? sort : null,
      table: key,
      tableSettings: headers
          ? headers.map(item => {
            return item.key
          })
          : null
    }

    for (let propName in tableSetting) {
      if (!['sort', 'filter', 'key', 'headers'].includes(propName)) {
        payload[propName] = tableSetting[propName]
      }
    }
    return payload
  },
  isObjInsidePopup: state => key => {
    let foundPopup = false
    for (let popup of state.popups) {
      if (popup.key == key) return popup.content
      let element = findTarget(popup.content, key)
      if (element.path.length > 0) {
        let index = state.popups.indexOf(popup)
        if (index > -1) {
          foundPopup = popup
        }
      }
    }
    return foundPopup
  },
  getInsideOfPopupKey: state => key => {
    let foundPopup = false
    for (let popup of state.popups) {
      let element = findTarget(popup, key)
      if (element.path) {
        let index = state.popups.indexOf(popup)
        if (index > -1) {
          foundPopup = popup.key
        }
      }
    }
    return foundPopup
  },
  getInsideOfFormKey: state => key => {
    let container = {}
    let elementPath = {}
    let formKey = null

    //search key in all popups first
    for (let popup of state.popups) {
      let element = findTarget(popup.content, key)
      if (element.path) {
        let index = state.popups.indexOf(popup)
        if (index > -1) {
          container = popup.content
          elementPath = element
        }
      }
    }

    //search key in MainJSON
    if (container !== {}) {
      let element = findTarget(state.mainJSON, key)
      if (element && element.path.length > 0) {
        container = state.mainJSON
        elementPath = element
      }
    }

    //search key in MainJSON
    if (container !== {}) {
      let element = findTarget(state.authJSON, key)
      if (element && element.path.length > 0) {
        container = state.authJSON
        elementPath = element
      }
    }

    //search all forms in container and get last form before key is found
    if (elementPath.path && elementPath.path.length > 0) {
      for (let index = 0; index < elementPath.path.length - 1; index++) {
        const path = elementPath.path[index]
        container = container.children[path]
        if (container && (container.type == 'lh-form' || container.type == 'LhForm')) {
          formKey = container.key
        }
      }
    }
    return formKey
  },
  getLengthOfSelectedRows: state => key => {
    let browse = state.browseSettings.find(item => item.key === key)
    return browse ? browse.selectedRows.length : null
  },

  // check for translation key in store translations
  getTranslationKey: state => getKey => {
    let foundKeys = []
    let entries = Object.entries(state.translations)
    entries.forEach(value => {
      if (value[1][getKey]) {
        foundKeys.push({
          [value[0]]: {
            key: getKey,
            value: value[1][getKey]
          }
        })
      }
      else {
        foundKeys.push({
          [value[0]]: {
            key: getKey,
            value: ''
          }
        })
      }
    })
    return foundKeys.length ? foundKeys : null
    // return entries
  },

  // check for translation key in specific language
  getTranslationKeyByLang: state => data => {
    console.log(data)
    return !!state.translations[data.lang][data.key]
  }
}

export default getters
