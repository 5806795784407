<template>
  <div class="home">
    <component :is="main.type" :obj="main" :key="main.key">Only slot</component>
  </div>
</template>

<script>

  export default {
    name: 'home',
    data () {
      return {
        added: false,
        row1Key: false,
        message: {
          'key': 'Root',
          'type': 'LhRoot',
          'actions': [],
          'attributes': [],
          'styles': {
            'width': '100%'
          },
          'children': [
            {
              'key': 'Root_Sidebar',
              'type': 'LhSidebar',
              'actions': [],
              'attributes': [],
              'styles': [],
              'children': [{
                'key': 'root-menu-logo-client',
                'type': 'LhImage',
                'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                'attributes': {
                  'src': 'https://api.dev.automationtool.technieken.be/assets/img/logo.svg',
                  'collapse-src': 'https://api.dev.automationtool.technieken.be/assets/img/logo_small.svg',
                  'class': 'client-logo'
                },
                'styles': { 'cursor': 'pointer' },
                'children': []
              }, {
                'key': 'root-menu-scroll-content',
                'type': 'LhSidebarScrollContent',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-nav-list',
                  'type': 'LhNavList',
                  'actions': [],
                  'attributes': { 'collapseSubNav': 'true' },
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-nav-list-item-1',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'Home', 'action': '/', 'icon': 'home', 'collapseSubNav': 'true' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-2',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'Clients', 'action': '/clients', 'icon': 'file-alt' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-3',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': {
                      'content': 'Projects',
                      'action': '/projects',
                      'icon': 'chart-pie',
                      'collapseSubNav': 'true'
                    },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-4',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'Pages', 'action': '/pages', 'icon': 'users', 'collapseSubNav': 'true' },
                    'styles': [],
                    'children': [
                      {
                        'key': 'root-menu-nav-sub-list-5',
                        'type': 'LhNavList',
                        'actions': [],
                        'attributes': { 'collapseSubNav': 'true' },
                        'styles': [],
                        'children': [{
                          'key': 'root-menu-nav-sub-list-5-item-1',
                          'type': 'LhNavListItem',
                          'actions': [],
                          'attributes': { 'content': 'Users', 'action': '/users', 'collapseSubNav': 'true' },
                          'styles': [],
                          'children': [{
                            'key': 'root-menu-nav-sub-list-5',
                            'type': 'LhNavList',
                            'actions': [],
                            'attributes': [],
                            'styles': [],
                            'children': [{
                              'key': 'root-menu-nav-sub-list-6-item-1',
                              'type': 'LhNavListItem',
                              'actions': [],
                              'attributes': { 'content': 'Block 1', 'action': '/users', 'collapseSubNav': 'true' },
                              'styles': [],
                              'children': []
                            }, {
                              'key': 'root-menu-nav-sub-list-6-item-2',
                              'type': 'LhNavListItem',
                              'actions': [],
                              'attributes': { 'content': 'Block 2', 'action': '/pageblocks', 'collapseSubNav': 'true' },
                              'styles': [],
                              'children': []
                            }]
                          }]
                        }, {
                          'key': 'root-menu-nav-sub-list-5-item-2',
                          'type': 'LhNavListItem',
                          'actions': [],
                          'attributes': { 'content': 'Blocks', 'action': '/pageblocks', 'collapseSubNav': 'true' },
                          'styles': [],
                          'children': [{
                            'key': 'root-menu-nav-sub-list-5',
                            'type': 'LhNavList',
                            'actions': [],
                            'attributes': [],
                            'styles': [],
                            'children': [{
                              'key': 'root-menu-nav-sub-list-5-item-1',
                              'type': 'LhNavListItem',
                              'actions': [],
                              'attributes': { 'content': 'Block 1', 'action': '/users', 'collapseSubNav': 'true' },
                              'styles': [],
                              'children': []
                            }, {
                              'key': 'root-menu-nav-sub-list-5-item-2',
                              'type': 'LhNavListItem',
                              'actions': [],
                              'attributes': { 'content': 'Block 2', 'action': '/pageblocks', 'collapseSubNav': 'true' },
                              'styles': [],
                              'children': []
                            }]
                          }]
                        }]
                      }
                    ]
                  }, {
                    'key': 'root-menu-nav-list-item-6',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': {
                      'content': 'Pages2',
                      'action': '/pages',
                      'icon': 'users',
                      'collapseSubNav': 'true'
                    },
                    'styles': [],
                    'children': [
                      {
                        'key': 'root-menu-nav-sub-list-5',
                        'type': 'LhNavList',
                        'actions': [],
                        'attributes': [],
                        'styles': [],
                        'children': [{
                          'key': 'root-menu-nav-sub-list-5-item-1',
                          'type': 'LhNavListItem',
                          'actions': [],
                          'attributes': { 'content': 'Users', 'action': '/users', 'collapseSubNav': 'true' },
                          'styles': [],
                          'children': [{
                            'key': 'root-menu-nav-sub-list-5',
                            'type': 'LhNavList',
                            'actions': [],
                            'attributes': [],
                            'styles': [],
                            'children': [{
                              'key': 'root-menu-nav-sub-list-6-item-1',
                              'type': 'LhNavListItem',
                              'actions': [],
                              'attributes': { 'content': 'Block 1', 'action': '/users', 'collapseSubNav': 'true' },
                              'styles': [],
                              'children': []
                            }, {
                              'key': 'root-menu-nav-sub-list-6-item-2',
                              'type': 'LhNavListItem',
                              'actions': [],
                              'attributes': { 'content': 'Block 2', 'action': '/pageblocks', 'collapseSubNav': 'true' },
                              'styles': [],
                              'children': []
                            }]
                          }]
                        }, {
                          'key': 'root-menu-nav-sub-list-5-item-2',
                          'type': 'LhNavListItem',
                          'actions': [],
                          'attributes': { 'content': 'Blocks', 'action': '/pageblocks', 'collapseSubNav': 'true' },
                          'styles': [],
                          'children': [{
                            'key': 'root-menu-nav-sub-list-5',
                            'type': 'LhNavList',
                            'actions': [],
                            'attributes': [],
                            'styles': [],
                            'children': [{
                              'key': 'root-menu-nav-sub-list-5-item-1',
                              'type': 'LhNavListItem',
                              'actions': [],
                              'attributes': { 'content': 'Block 1', 'action': '/users', 'collapseSubNav': 'true' },
                              'styles': [],
                              'children': []
                            }, {
                              'key': 'root-menu-nav-sub-list-5-item-2',
                              'type': 'LhNavListItem',
                              'actions': [],
                              'attributes': { 'content': 'Block 2', 'action': '/pageblocks', 'collapseSubNav': 'true' },
                              'styles': [],
                              'children': []
                            }]
                          }]
                        }]
                      }
                    ]
                  }, {
                    'key': 'root-menu-nav-list-item-5',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'Config', 'action': '/users', 'icon': 'cog' },
                    'styles': [],
                    'children': [{
                      'key': 'root-menu-nav-sub-list-5',
                      'type': 'LhNavList',
                      'actions': [],
                      'attributes': [],
                      'styles': [],
                      'children': [{
                        'key': 'root-menu-nav-sub-list-5-item-1',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_USERS', 'action': '/users', 'collapseSubNav': 'true' },
                        'styles': [],
                        'children': []
                      }, {
                        'key': 'root-menu-nav-sub-list-5-item-2',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': {
                          'content': 'SIDEBAR_BLOCKS',
                          'action': '/pageblocks',
                          'collapseSubNav': 'true'
                        },
                        'styles': [],
                        'children': []
                      }]
                    }]
                  }]
                }]
              }, {
                'key': 'root-menu-logo-group',
                'type': 'LhDiv',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-logo-lighthouse',
                  'type': 'LhImage',
                  'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                  'attributes': {
                    'src': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse_logo_grey.svg',
                    'class': 'lighthouse-logo'
                  },
                  'styles': { 'cursor': 'pointer' },
                  'children': []
                }]
              }, {
                'key': 'root-menu-button-group',
                'type': 'LhButtonGroup',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-col-1',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-user-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/settings', 'type': 'update', 'method': 'GET', 'event': 'click' }],
                    'attributes': { 'icon': 'user-cog', 'class': 'sidebar-user-settings-button' },
                    'styles': [],
                    'children': []
                  }]
                }, {
                  'key': 'root-menu-col-2',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-logout-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/logout', 'type': 'redirect', 'method': 'POST', 'event': 'click' }],
                    'attributes': { 'icon': 'sign-out-alt', 'class': 'sidebar-user-logout-button' },
                    'styles': [],
                    'children': []
                  }]
                }]
              }]
            },
            {
              'key': 'project-main-page',
              'type': 'LhMainPage',
              'actions': [],
              'attributes': [],
              'styles': [],
              'children': [
                {
                  'key': 'Root_MainPage_MobileHeader',
                  'type': 'LhPageHeader',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': null,
                    'type': 'LhImage',
                    'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                    'attributes': {
                      'src': 'https://api.dev.automationtool.technieken.be/assets/img/logo.svg',
                      'collapse-src': 'https://api.dev.automationtool.technieken.be/assets/img/logo_small.svg',
                      'class': 'client-logo'
                    },
                    'styles': { 'cursor': 'pointer' },
                    'children': []
                  }]
                },
                {
                  'key': 'Root_MainPage_Title',
                  'type': 'LhH1',
                  'actions': [],
                  'attributes': {
                    content: 'Components demo'
                  },
                  'styles': [],
                  'children': []
                },
                {
                  'key': 'demo-tabs',
                  'type': 'LhTabs',
                  'actions': [],
                  'attributes': {
                    'target': 'patient-filter-popup-group',
                    'verticalTabs': 'true'
                  },
                  'styles': [],
                  'children': [
                    {
                      'key': 'tags-tab',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'LhInputTags'
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'tags-tab2',
                          'type': 'LhInput',
                          'actions': [],
                          'attributes': {
                            'validation': {
                              'type': 'email',
                              'translation_key': 'Invalid email'
                            },
                            'label': 'LhInputTags',
                            'type': 'inputtags'
                          },
                          'styles': [],
                          'children': []
                        }
                      ]
                    },
                    {
                      'key': 'radio-three-tab',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'Boolean radio option'
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'boolean-radio-tab',
                          'type': 'LhInput',
                          'actions': [],
                          'attributes': {
                            'label': 'Inside sales',
                            'type': 'radio'
                          },
                          'styles': [],
                          'children': [
                            {
                              'key': null,
                              'type': null,
                              'actions': [],
                              'attributes': { 'value': 'null', 'content': 'Unset' },
                              'styles': [],
                              'children': []
                            }, {
                              'key': null,
                              'type': null,
                              'actions': [],
                              'attributes': { 'value': '0', 'content': 'Show' },
                              'styles': [],
                              'children': []
                            },
                            {
                              'key': null,
                              'type': null,
                              'actions': [],
                              'attributes': { 'value': '1', 'content': 'Hide' },
                              'styles': [],
                              'children': []
                            }
                          ]
                        },
                        {
                          'key': 'boolean-select',
                          'type': 'LhInput',
                          'actions': [],
                          'attributes': {
                            'type': 'select',
                            'label': 'Inside sales',
                            'name': 'inside_sales',
                            'id': 'visitorder-inside-sales-select',
                            'value': 'null',
                            'content': [{
                              'text': 'GENERAL_CHOOSE',
                              'value': ''
                            }, {
                              'value': '1',
                              'text': 'Show'
                            }, {
                              'value': '0',
                              'text': 'Hide'
                            }],
                            'disabled': false,
                            'labelCols': 12
                          },
                          'styles': [],
                          'children': []
                        },
                      ]
                    },
                    {
                      'key': 'anchor-tab',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'LhA'
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'tags-tab3',
                          'type': 'LhA',
                          'actions': [],
                          'attributes': {
                            'content': 'google',
                            'href': 'https://google.be',
                            'target': '_blank',
                            'class': 'button tertiary'
                          },
                          'styles': [],
                          'children': []
                        }
                      ]
                    },
                    {
                      'key': 'indicators-tab',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'LhColorIndicator'
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'indicator-tab',
                          'type': 'LhColorIndicator',
                          'actions': [],
                          'attributes': {
                            'content': '#f84e56'
                          },
                          'styles': [],
                          'children': []
                        }
                      ]
                    },
                    {
                      'key': 'tags-tab4',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'LhTag'
                      },
                      'key': 'tags-tab5',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'LhTag'
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'tag1',
                          'type': 'LhTag',
                          'actions': [],
                          'attributes': {
                            'priority': 'low',
                            'content': 'Visit',
                            'dueDate': '2020-11-10T00:00:00',
                            'tooltip': 'test'
                          },
                          'styles': [],
                          'children': []
                        },
                        {
                          'key': 'tag2',
                          'type': 'LhTag',
                          'actions': [],
                          'attributes': {
                            'priority': 'bc87f4',
                            'content': 'Action',
                            'dueDate': '2020-10-26T00:00:00'
                          },
                          'styles': [],
                          'children': []
                        },
                        {
                          'key': 'tag3',
                          'type': 'LhTag',
                          'actions': [],
                          'attributes': {
                            'priority': 'high',
                            'content': 'Visit',
                            'dueDate': '2020-10-14T00:00:00'
                          },
                          'styles': [],
                          'children': []
                        },
                        {
                          'key': 'tag4',
                          'type': 'LhTag',
                          'actions': [],
                          'attributes': {
                            'removeCloseButton': true,
                            'content': 'Call',
                          },
                          'styles': [],
                          'children': []
                        },
                        {
                          'key': 'icon',
                          'type': 'LhIcon',
                          'actions': [],
                          'attributes': {
                            'class': 'press-animation press-animation--color',
                            'icon': 'plus-circle',
                          },
                          'styles': {
                            'font-size': '1.5rem'
                          },
                          'children': []
                        },
                      ]
                    },
                    {
                      'key': 'opening-hours-tab7',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'LhWidget type 2'
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'pharmacy-dashboard-main-row-2',
                          'type': 'LhRow',
                          'actions': [],
                          'attributes': {
                            'class': 'widget-row',
                          },
                          'styles': {
                            'margin-top': '20px'
                          },
                          'children': [
                            {
                              'key': 'pharmacy-dashboard-main-col-2-1',
                              'type': 'LhCol',
                              'actions': [],
                              'attributes': {
                                'cols': 'auto',
                                'md': 3
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'pharmacy-dashboard-widget-1',
                                  'type': 'LhWidget',
                                  'actions': [],
                                  'attributes': {
                                    'title': 'Last visit',
                                    'icon': 'calendar-alt',
                                    'stat': '23 - 10 - 2020',
                                    'type': 'type2'
                                  },
                                  'styles': {
                                    'cursor': 'pointer'
                                  },
                                  'children': []
                                }
                              ]
                            },
                            {
                              'key': 'pharmacy-dashboard-main-col-2-2',
                              'type': 'LhCol',
                              'actions': [],
                              'attributes': {
                                'cols': 'auto',
                                'md': 3
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'pharmacy-dashboard-widget-2',
                                  'type': 'LhWidget',
                                  'actions': [],
                                  'attributes': {
                                    'title': 'PLACEHOLDER',
                                    'icon': 'calendar-alt',
                                    'stat': '',
                                    'type': 'type2'
                                  },
                                  'styles': {
                                    'cursor': 'pointer'
                                  },
                                  'children': []
                                }
                              ]
                            },
                            {
                              'key': 'pharmacy-dashboard-main-col-2-3',
                              'type': 'LhCol',
                              'actions': [],
                              'attributes': {
                                'cols': 'auto',
                                'md': 3
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'pharmacy-dashboard-widget-3',
                                  'type': 'LhWidget',
                                  'actions': [],
                                  'attributes': {
                                    'title': 'PLACEHOLDER',
                                    'icon': 'paperclip',
                                    'stat': '100%',
                                    'type': 'type2'
                                  },
                                  'styles': {
                                    'cursor': 'pointer'
                                  },
                                  'children': []
                                }
                              ]
                            },
                            {
                              'key': 'pharmacy-dashboard-main-col-2-4',
                              'type': 'LhCol',
                              'actions': [],
                              'attributes': {
                                'cols': 'auto',
                                'md': 3
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'pharmacy-dashboard-widget-4',
                                  'type': 'LhWidget',
                                  'actions': [],
                                  'attributes': {
                                    'title': 'PLACEHOLDER',
                                    'icon': 'paperclip',
                                    'stat': '',
                                    'type': 'type2'
                                  },
                                  'styles': {
                                    'cursor': 'pointer'
                                  },
                                  'children': []
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      'key': 'opening-hours-tab',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'LhBusinessHours compact'
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'opening',
                          'type': 'LhBusinessHoursCompact',
                          'actions': [],
                          'attributes': {
                            'direction': 'horizontal',
                            'days': ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
                            'value': {
                              'AM': ['true', 'true', 'true', 'true', 'true', 'false', 'false'],
                              'PM': ['true', 'false', 'true', 'true', 'true', 'false', 'false']
                            }
                          },
                          'styles': {},
                          'children': []
                        },
                        {
                          'key': 'spacer2',
                          'type': 'LhDiv',
                          'actions': [],
                          'attributes': {},
                          'styles': { 'padding-top': '2rem' },
                          'children': []
                        },
                        {
                          'key': 'opening2',
                          'type': 'LhBusinessHours',
                          'actions': [],
                          'attributes': {
                            'direction': 'vertical',
                            'hideTitle': 'true',
                            'days': ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
                            'value': {
                              'AM': ['true', 'true', 'true', 'true', 'true', 'false', 'false'],
                              'PM': ['true', 'false', 'true', 'true', 'true', 'false', 'false'],
                              'Custom': ['8u-15u', '8u-15u', '8u-15u', '8u-15u', '8u-15u', 'GENERAL_CLOSED', 'GENERAL_CLOSED'],
                            }
                          },
                          'styles': {},
                          'children': []
                        },
                        {
                          'key': 'spacer3',
                          'type': 'LhDiv',
                          'actions': [],
                          'attributes': {},
                          'styles': { 'padding-top': '2rem' },
                          'children': []
                        },
                        {
                          'key': 'opening3',
                          'type': 'LhBusinessHours',
                          'actions': [],
                          'attributes': {
                            'direction': 'vertical',
                            'days': ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
                            'value': {
                              'AM': ['true', 'true', 'true', 'true', 'true', 'false', 'false'],
                              'PM': ['true', 'false', 'true', 'true', 'true', 'false', 'false'],
                              'Custom': ['8u-15u', '8u-15u', '8u-15u', '8u-15u', '8u-15u', 'GENERAL_CLOSED', 'GENERAL_CLOSED'],
                            }
                          },
                          'styles': {},
                          'children': []
                        },
                        {
                          'key': 'spacer4',
                          'type': 'LhDiv',
                          'actions': [],
                          'attributes': {},
                          'styles': { 'padding-top': '2rem' },
                          'children': []
                        },
                      ]
                    },
                    {
                      'key': 'opening-hours-tab-input',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'LhBusinessHours input'
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'form',
                          'type': 'LhForm',
                          'actions': [],
                          'attributes': {},
                          'styles': {},
                          'children': [
                            {
                              'key': 'opening10',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'businesshour',
                                'label': 'businesshour-input',
                                'name': 'businesshour-input',
                                'direction': 'horizontal',
                                'days': ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
                                'value': {
                                  'AM': ['true', 'true', 'true', 'true', 'true', 'false', 'false'],
                                  'PM': ['true', 'false', 'true', 'true', 'true', 'false', 'false'],
                                  'Custom': ['8u-15u', '8u-15u', '8u-15u', '8u-15u', '8u-15u', '-', '-'],
                                  'Custom2': ['8u-15u', '8u-15u', '8u-15u', '8u-15u', '8u-15u', '-', '-'],
                                }
                              },
                              'styles': {},
                              'children': []
                            },
                            {
                              'key': 'openin20g',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'businesshour',
                                'label': 'Input business hours',
                                'direction': 'vertical',
                                'days': ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
                                'value': {
                                  'AM': ['true', 'true', 'true', 'true', 'true', 'false', 'false'],
                                  'PM': ['true', 'false', 'true', 'true', 'true', 'false', 'false'],
                                  'Custom': ['8u-15u', '8u-15u', '8u-15u', '8u-15u', '8u-15u', '-', '-'],
                                  'Custom2': ['8u-15u', '8u-15u', '8u-15u', '8u-15u', '8u-15u', '-', '-'],
                                }
                              },
                              'styles': {},
                              'children': []
                            }
                          ]
                        }
                      ]
                    },
                    {
                      'key': 'switcher-tab',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'LhWrappedSwitcher + GridLite'
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'wrapped-switcher',
                          'type': 'LhDiv',
                          'actions': [],
                          'attributes': {
                            'title': 'LhGrid - hideHeaders',
                            'buttonType': 'arrows',
                            'url': '/visits/add?subBrowse=1'
                          },
                          'styles': {},
                          'children': [
                            {
                              'key': 'complex-browse-visits',
                              'type': 'LhGridLite',
                              'actions': [],
                              'attributes': {
                                'url': '/visits',
                                'hideHeaders': true,
                                'activeFilters': null
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'row-0',
                                  'type': 'LhGridRow',
                                  'actions': [
                                    []
                                  ],
                                  'attributes': [],
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-visit-date-heading',
                                      'type': 'LhGridHeader',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'VISIT_DATE',
                                        'cellType': 'data',
                                        'checked': true,
                                        'showMobile': true
                                      },
                                      'styles': {
                                        'fontWeight': '900'
                                      },
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-type-heading',
                                      'type': 'LhGridHeader',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'VISIT_TYPE',
                                        'cellType': 'data',
                                        'checked': true,
                                        'showMobile': true
                                      },
                                      'styles': {
                                        'fontWeight': '900'
                                      },
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-duration-heading',
                                      'type': 'LhGridHeader',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'VISIT_DURATION',
                                        'cellType': 'data',
                                        'checked': true,
                                        'showMobile': true
                                      },
                                      'styles': {
                                        'fontWeight': '900'
                                      },
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-nurse-heading',
                                      'type': 'LhGridHeader',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'VISIT_NURSE',
                                        'cellType': 'data',
                                        'checked': true,
                                        'showMobile': true
                                      },
                                      'styles': {
                                        'fontWeight': '900'
                                      },
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-succession-heading',
                                      'type': 'LhGridHeader',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'VISIT_SUCCESSION',
                                        'cellType': 'data',
                                        'checked': true,
                                        'showMobile': true
                                      },
                                      'styles': {
                                        'fontWeight': '900'
                                      },
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-action-heading',
                                      'type': 'LhGridHeader',
                                      'actions': [],
                                      'attributes': {
                                        'cellType': 'button',
                                        'class': 'cell-sticky'
                                      },
                                      'styles': {
                                        'fontWeight': '900'
                                      },
                                      'children': []
                                    }
                                  ]
                                },
                                {
                                  'key': 'visit-browse-row-1',
                                  'type': 'LhGridRow',
                                  'actions': [
                                    {
                                      'url': '/visits/detail/0713b703d83229ba8f4d932f14705313e7e4fabf4b7ffce40c953f66a441adabeb2c12fe263a78b651c722d69738b3a64824ea7bde6dd4b53523f796a19abfee',
                                      'type': 'update',
                                      'method': 'GET',
                                      'event': 'dblclick'
                                    }
                                  ],
                                  'attributes': {
                                    'guid': '0713b703d83229ba8f4d932f14705313e7e4fabf4b7ffce40c953f66a441adabeb2c12fe263a78b651c722d69738b3a64824ea7bde6dd4b53523f796a19abfee'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-date1',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '2020-03-10 10:00',
                                        'target': 'visit-visit-date-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-type1',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Type 2',
                                        'target': 'visit-type-heading'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'indicator-tab',
                                          'type': 'LhColorIndicator',
                                          'actions': [],
                                          'attributes': {
                                            'value': '#f84e56'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    },
                                    {
                                      'key': 'visit-duration1',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '40',
                                        'target': 'visit-duration-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-nurse-1',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Vereecken Bjorn',
                                        'target': 'visit-nurse-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-succession-1',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Low',
                                        'target': 'visit-succession-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-actions-1',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'target': 'visit-action-heading',
                                        'class': 'cell-sticky'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'visit-edit-button-1',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/detail/0713b703d83229ba8f4d932f14705313e7e4fabf4b7ffce40c953f66a441adabeb2c12fe263a78b651c722d69738b3a64824ea7bde6dd4b53523f796a19abfee',
                                              'type': 'update',
                                              'method': 'GET',
                                              'event': 'click'
                                            }
                                          ],
                                          'attributes': {
                                            'content': 'VISIT_BROWSE_DETAIL',
                                            'icon': 'pencil-alt'
                                          },
                                          'styles': [],
                                          'children': []
                                        },
                                        {
                                          'key': 'visit-delete-button1',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/delete',
                                              'type': 'submit',
                                              'method': 'POST',
                                              'event': 'click',
                                              'confirmation': {
                                                'title': 'VISIT_DELETE_TITLE',
                                                'content': 'VISIT_DELETE_MESSAGE',
                                                'confirm': 'GENERAL_CONFIRM',
                                                'cancel': 'GENERAL_CANCEL'
                                              }
                                            }
                                          ],
                                          'attributes': {
                                            'name': 'testBtn',
                                            'content': 'VISIT_BROWSE_DELETE',
                                            'value': '0713b703d83229ba8f4d932f14705313e7e4fabf4b7ffce40c953f66a441adabeb2c12fe263a78b651c722d69738b3a64824ea7bde6dd4b53523f796a19abfee',
                                            'icon': 'trash-alt',
                                            'type': 'delete'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  'key': 'visit-browse-row-2',
                                  'type': 'LhGridRow',
                                  'actions': [
                                    {
                                      'url': '/visits/detail/dac89db0956971c0a524de46bf120bd4e1995f6accbb7afe11d0afcf1daa36d5ce4743b502b11652d69a7ffa6c2d5fc9b5eaaf75f7ca7ad945fae7b0aa670331',
                                      'type': 'update',
                                      'method': 'GET',
                                      'event': 'dblclick'
                                    }
                                  ],
                                  'attributes': {
                                    'guid': 'dac89db0956971c0a524de46bf120bd4e1995f6accbb7afe11d0afcf1daa36d5ce4743b502b11652d69a7ffa6c2d5fc9b5eaaf75f7ca7ad945fae7b0aa670331'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-date2',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '2020-03-10 10:00',
                                        'target': 'visit-visit-date-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-type2',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Type 1',
                                        'target': 'visit-type-heading'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'indicator-tab',
                                          'type': 'LhColorIndicator',
                                          'actions': [],
                                          'attributes': {
                                            'value': 'c87b56'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    },
                                    {
                                      'key': 'visit-duration2',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '40',
                                        'target': 'visit-duration-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-nurse-2',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Maes Ben',
                                        'target': 'visit-nurse-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-succession-2',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Med',
                                        'target': 'visit-succession-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-actions-2',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'target': 'visit-action-heading',
                                        'class': 'cell-sticky'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'visit-edit-button-2',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/detail/dac89db0956971c0a524de46bf120bd4e1995f6accbb7afe11d0afcf1daa36d5ce4743b502b11652d69a7ffa6c2d5fc9b5eaaf75f7ca7ad945fae7b0aa670331',
                                              'type': 'update',
                                              'method': 'GET',
                                              'event': 'click'
                                            }
                                          ],
                                          'attributes': {
                                            'content': 'VISIT_BROWSE_DETAIL',
                                            'icon': 'pencil-alt'
                                          },
                                          'styles': [],
                                          'children': []
                                        },
                                        {
                                          'key': 'visit-delete-button2',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/delete',
                                              'type': 'submit',
                                              'method': 'POST',
                                              'event': 'click',
                                              'confirmation': {
                                                'title': 'VISIT_DELETE_TITLE',
                                                'content': 'VISIT_DELETE_MESSAGE',
                                                'confirm': 'GENERAL_CONFIRM',
                                                'cancel': 'GENERAL_CANCEL'
                                              }
                                            }
                                          ],
                                          'attributes': {
                                            'name': 'testBtn',
                                            'content': 'VISIT_BROWSE_DELETE',
                                            'value': 'dac89db0956971c0a524de46bf120bd4e1995f6accbb7afe11d0afcf1daa36d5ce4743b502b11652d69a7ffa6c2d5fc9b5eaaf75f7ca7ad945fae7b0aa670331',
                                            'icon': 'trash-alt',
                                            'type': 'delete'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  'key': 'visit-browse-row-3',
                                  'type': 'LhGridRow',
                                  'actions': [
                                    {
                                      'url': '/visits/detail/2d2ab92366e362cff121307d230d5468bcf258de163ffb2e9e760ad16a6153636a1c40fe0762dc69a217c9c385cb3eaa1e75bcc4564b012d4715a23fbe783bae',
                                      'type': 'update',
                                      'method': 'GET',
                                      'event': 'dblclick'
                                    }
                                  ],
                                  'attributes': {
                                    'guid': '2d2ab92366e362cff121307d230d5468bcf258de163ffb2e9e760ad16a6153636a1c40fe0762dc69a217c9c385cb3eaa1e75bcc4564b012d4715a23fbe783bae'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-date3',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '2020-02-16 11:02',
                                        'target': 'visit-visit-date-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-type3',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Type 1',
                                        'target': 'visit-type-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-duration3',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': null,
                                        'target': 'visit-duration-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-nurse-3',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'test verpleeger1',
                                        'target': 'visit-nurse-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-succession-3',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'High',
                                        'target': 'visit-succession-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-actions-3',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'target': 'visit-action-heading',
                                        'class': 'cell-sticky'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'visit-edit-button-3',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/detail/2d2ab92366e362cff121307d230d5468bcf258de163ffb2e9e760ad16a6153636a1c40fe0762dc69a217c9c385cb3eaa1e75bcc4564b012d4715a23fbe783bae',
                                              'type': 'update',
                                              'method': 'GET',
                                              'event': 'click'
                                            }
                                          ],
                                          'attributes': {
                                            'content': 'VISIT_BROWSE_DETAIL',
                                            'icon': 'pencil-alt'
                                          },
                                          'styles': [],
                                          'children': []
                                        },
                                        {
                                          'key': 'visit-delete-button3',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/delete',
                                              'type': 'submit',
                                              'method': 'POST',
                                              'event': 'click',
                                              'confirmation': {
                                                'title': 'VISIT_DELETE_TITLE',
                                                'content': 'VISIT_DELETE_MESSAGE',
                                                'confirm': 'GENERAL_CONFIRM',
                                                'cancel': 'GENERAL_CANCEL'
                                              }
                                            }
                                          ],
                                          'attributes': {
                                            'name': 'testBtn',
                                            'content': 'VISIT_BROWSE_DELETE',
                                            'value': '2d2ab92366e362cff121307d230d5468bcf258de163ffb2e9e760ad16a6153636a1c40fe0762dc69a217c9c385cb3eaa1e75bcc4564b012d4715a23fbe783bae',
                                            'icon': 'trash-alt',
                                            'type': 'delete'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  'key': 'visit-browse-row-4',
                                  'type': 'LhGridRow',
                                  'actions': [
                                    {
                                      'url': '/visits/detail/f6c80600e6c79693c20add70a4e65d32b8cfb8ac38c127237ee892d20eb05ac14e7269855abdebee2e16d38ba6baec6e9d6cb62306d6ff307e0d658939289a9a',
                                      'type': 'update',
                                      'method': 'GET',
                                      'event': 'dblclick'
                                    }
                                  ],
                                  'attributes': {
                                    'guid': 'f6c80600e6c79693c20add70a4e65d32b8cfb8ac38c127237ee892d20eb05ac14e7269855abdebee2e16d38ba6baec6e9d6cb62306d6ff307e0d658939289a9a'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-date4',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '2020-02-11 10:37',
                                        'target': 'visit-visit-date-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-type4',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Type 1',
                                        'target': 'visit-type-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-duration4',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '10',
                                        'target': 'visit-duration-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-nurse-4',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Vereecken Bjorn',
                                        'target': 'visit-nurse-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-succession-4',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'High',
                                        'target': 'visit-succession-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-actions-4',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'target': 'visit-action-heading',
                                        'class': 'cell-sticky'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'visit-edit-button-4',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/detail/f6c80600e6c79693c20add70a4e65d32b8cfb8ac38c127237ee892d20eb05ac14e7269855abdebee2e16d38ba6baec6e9d6cb62306d6ff307e0d658939289a9a',
                                              'type': 'update',
                                              'method': 'GET',
                                              'event': 'click'
                                            }
                                          ],
                                          'attributes': {
                                            'content': 'VISIT_BROWSE_DETAIL',
                                            'icon': 'pencil-alt'
                                          },
                                          'styles': [],
                                          'children': []
                                        },
                                        {
                                          'key': 'visit-delete-button4',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/delete',
                                              'type': 'submit',
                                              'method': 'POST',
                                              'event': 'click',
                                              'confirmation': {
                                                'title': 'VISIT_DELETE_TITLE',
                                                'content': 'VISIT_DELETE_MESSAGE',
                                                'confirm': 'GENERAL_CONFIRM',
                                                'cancel': 'GENERAL_CANCEL'
                                              }
                                            }
                                          ],
                                          'attributes': {
                                            'name': 'testBtn',
                                            'content': 'VISIT_BROWSE_DELETE',
                                            'value': 'f6c80600e6c79693c20add70a4e65d32b8cfb8ac38c127237ee892d20eb05ac14e7269855abdebee2e16d38ba6baec6e9d6cb62306d6ff307e0d658939289a9a',
                                            'icon': 'trash-alt',
                                            'type': 'delete'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  'key': 'visit-browse-row-5',
                                  'type': 'LhGridRow',
                                  'actions': [
                                    {
                                      'url': '/visits/detail/2971fe92682a2e1b001967a97c49d1034d06f9866b21b60d0577e699de7aaa6331a71a72e614d136bd7798136a73a3022d8dc25c38b5e0ac4dfbc150a5a38f8a',
                                      'type': 'update',
                                      'method': 'GET',
                                      'event': 'dblclick'
                                    }
                                  ],
                                  'attributes': {
                                    'guid': '2971fe92682a2e1b001967a97c49d1034d06f9866b21b60d0577e699de7aaa6331a71a72e614d136bd7798136a73a3022d8dc25c38b5e0ac4dfbc150a5a38f8a'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-date5',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '2020-02-11 10:32',
                                        'target': 'visit-visit-date-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-type5',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Type 1',
                                        'target': 'visit-type-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-duration5',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'test',
                                        'target': 'visit-duration-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-nurse-5',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Detemmerman Jordy',
                                        'target': 'visit-nurse-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-succession-5',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'High',
                                        'target': 'visit-succession-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-actions-5',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'target': 'visit-action-heading',
                                        'class': 'cell-sticky'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'visit-edit-button-5',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/detail/2971fe92682a2e1b001967a97c49d1034d06f9866b21b60d0577e699de7aaa6331a71a72e614d136bd7798136a73a3022d8dc25c38b5e0ac4dfbc150a5a38f8a',
                                              'type': 'update',
                                              'method': 'GET',
                                              'event': 'click'
                                            }
                                          ],
                                          'attributes': {
                                            'content': 'VISIT_BROWSE_DETAIL',
                                            'icon': 'pencil-alt'
                                          },
                                          'styles': [],
                                          'children': []
                                        },
                                        {
                                          'key': 'visit-delete-button5',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/delete',
                                              'type': 'submit',
                                              'method': 'POST',
                                              'event': 'click',
                                              'confirmation': {
                                                'title': 'VISIT_DELETE_TITLE',
                                                'content': 'VISIT_DELETE_MESSAGE',
                                                'confirm': 'GENERAL_CONFIRM',
                                                'cancel': 'GENERAL_CANCEL'
                                              }
                                            }
                                          ],
                                          'attributes': {
                                            'name': 'testBtn',
                                            'content': 'VISIT_BROWSE_DELETE',
                                            'value': '2971fe92682a2e1b001967a97c49d1034d06f9866b21b60d0577e699de7aaa6331a71a72e614d136bd7798136a73a3022d8dc25c38b5e0ac4dfbc150a5a38f8a',
                                            'icon': 'trash-alt',
                                            'type': 'delete'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  'key': 'visit-browse-row-6',
                                  'type': 'LhGridRow',
                                  'actions': [
                                    {
                                      'url': '/visits/detail/94dc999e6f5414b9689f02cd9ed44451694a3ab32f515281bdd2f93df0b0aac24d7cf1df506baedf63ef4426c9cb49ab74c4f571bd8e418920f2dcf5396e98e4',
                                      'type': 'update',
                                      'method': 'GET',
                                      'event': 'dblclick'
                                    }
                                  ],
                                  'attributes': {
                                    'guid': '94dc999e6f5414b9689f02cd9ed44451694a3ab32f515281bdd2f93df0b0aac24d7cf1df506baedf63ef4426c9cb49ab74c4f571bd8e418920f2dcf5396e98e4'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-date6',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '2020-02-04 10:43',
                                        'target': 'visit-visit-date-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-type6',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Type 2',
                                        'target': 'visit-type-heading'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'indicator-tab',
                                          'type': 'LhColorIndicator',
                                          'actions': [],
                                          'attributes': {
                                            'value': '#f84e56'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    },
                                    {
                                      'key': 'visit-duration6',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '101',
                                        'target': 'visit-duration-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-nurse-6',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Vereecken Bjorn',
                                        'target': 'visit-nurse-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-succession-6',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'High',
                                        'target': 'visit-succession-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-actions-6',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'target': 'visit-action-heading',
                                        'class': 'cell-sticky'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'visit-edit-button-6',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/detail/94dc999e6f5414b9689f02cd9ed44451694a3ab32f515281bdd2f93df0b0aac24d7cf1df506baedf63ef4426c9cb49ab74c4f571bd8e418920f2dcf5396e98e4',
                                              'type': 'update',
                                              'method': 'GET',
                                              'event': 'click'
                                            }
                                          ],
                                          'attributes': {
                                            'content': 'VISIT_BROWSE_DETAIL',
                                            'icon': 'pencil-alt'
                                          },
                                          'styles': [],
                                          'children': []
                                        },
                                        {
                                          'key': 'visit-delete-button6',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/delete',
                                              'type': 'submit',
                                              'method': 'POST',
                                              'event': 'click',
                                              'confirmation': {
                                                'title': 'VISIT_DELETE_TITLE',
                                                'content': 'VISIT_DELETE_MESSAGE',
                                                'confirm': 'GENERAL_CONFIRM',
                                                'cancel': 'GENERAL_CANCEL'
                                              }
                                            }
                                          ],
                                          'attributes': {
                                            'name': 'testBtn',
                                            'content': 'VISIT_BROWSE_DELETE',
                                            'value': '94dc999e6f5414b9689f02cd9ed44451694a3ab32f515281bdd2f93df0b0aac24d7cf1df506baedf63ef4426c9cb49ab74c4f571bd8e418920f2dcf5396e98e4',
                                            'icon': 'trash-alt',
                                            'type': 'delete'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  'key': 'visit-browse-row-7',
                                  'type': 'LhGridRow',
                                  'actions': [
                                    {
                                      'url': '/visits/detail/84aabd792ce097a55dfe25681b51de64619552199e68352ab134896d772bce1f75ff37a00fa172cf2e883905452725f74b7c7d81a3113d23ce2792a36817ae39',
                                      'type': 'update',
                                      'method': 'GET',
                                      'event': 'dblclick'
                                    }
                                  ],
                                  'attributes': {
                                    'guid': '84aabd792ce097a55dfe25681b51de64619552199e68352ab134896d772bce1f75ff37a00fa172cf2e883905452725f74b7c7d81a3113d23ce2792a36817ae39'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-date7',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '2020-02-03 10:55',
                                        'target': 'visit-visit-date-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-type7',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Type 1',
                                        'target': 'visit-type-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-duration7',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': null,
                                        'target': 'visit-duration-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-nurse-7',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Detemmerman Jordy',
                                        'target': 'visit-nurse-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-succession-7',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'High',
                                        'target': 'visit-succession-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-actions-7',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'target': 'visit-action-heading',
                                        'class': 'cell-sticky'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'visit-edit-button-7',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/detail/84aabd792ce097a55dfe25681b51de64619552199e68352ab134896d772bce1f75ff37a00fa172cf2e883905452725f74b7c7d81a3113d23ce2792a36817ae39',
                                              'type': 'update',
                                              'method': 'GET',
                                              'event': 'click'
                                            }
                                          ],
                                          'attributes': {
                                            'content': 'VISIT_BROWSE_DETAIL',
                                            'icon': 'pencil-alt'
                                          },
                                          'styles': [],
                                          'children': []
                                        },
                                        {
                                          'key': 'visit-delete-button7',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/delete',
                                              'type': 'submit',
                                              'method': 'POST',
                                              'event': 'click',
                                              'confirmation': {
                                                'title': 'VISIT_DELETE_TITLE',
                                                'content': 'VISIT_DELETE_MESSAGE',
                                                'confirm': 'GENERAL_CONFIRM',
                                                'cancel': 'GENERAL_CANCEL'
                                              }
                                            }
                                          ],
                                          'attributes': {
                                            'name': 'testBtn',
                                            'content': 'VISIT_BROWSE_DELETE',
                                            'value': '84aabd792ce097a55dfe25681b51de64619552199e68352ab134896d772bce1f75ff37a00fa172cf2e883905452725f74b7c7d81a3113d23ce2792a36817ae39',
                                            'icon': 'trash-alt',
                                            'type': 'delete'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  'key': 'visit-browse-row-8',
                                  'type': 'LhGridRow',
                                  'actions': [
                                    {
                                      'url': '/visits/detail/a42d289767ef7dc72cac6ecf019c4f681a5156102280ab4d3ad290f863f9de933dab479322b944c6e5c1ead3cfa262dcd0aea35332c13008b635f055c2ce4b2d',
                                      'type': 'update',
                                      'method': 'GET',
                                      'event': 'dblclick'
                                    }
                                  ],
                                  'attributes': {
                                    'guid': 'a42d289767ef7dc72cac6ecf019c4f681a5156102280ab4d3ad290f863f9de933dab479322b944c6e5c1ead3cfa262dcd0aea35332c13008b635f055c2ce4b2d'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-date8',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '2020-01-10 10:00',
                                        'target': 'visit-visit-date-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-type8',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Type 3',
                                        'target': 'visit-type-heading'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'indicator-tab',
                                          'type': 'LhColorIndicator',
                                          'actions': [],
                                          'attributes': {
                                            'value': '#98f4e7'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    },
                                    {
                                      'key': 'visit-duration8',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': '40',
                                        'target': 'visit-duration-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-nurse-8',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Maes Ben',
                                        'target': 'visit-nurse-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-succession-8',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'Med',
                                        'target': 'visit-succession-heading'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-actions-8',
                                      'type': 'LhGridCell',
                                      'actions': [],
                                      'attributes': {
                                        'target': 'visit-action-heading',
                                        'class': 'cell-sticky'
                                      },
                                      'styles': [],
                                      'children': [
                                        {
                                          'key': 'visit-edit-button-8',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/detail/a42d289767ef7dc72cac6ecf019c4f681a5156102280ab4d3ad290f863f9de933dab479322b944c6e5c1ead3cfa262dcd0aea35332c13008b635f055c2ce4b2d',
                                              'type': 'update',
                                              'method': 'GET',
                                              'event': 'click'
                                            }
                                          ],
                                          'attributes': {
                                            'content': 'VISIT_BROWSE_DETAIL',
                                            'icon': 'pencil-alt'
                                          },
                                          'styles': [],
                                          'children': []
                                        },
                                        {
                                          'key': 'visit-delete-button8',
                                          'type': 'LhIconButton',
                                          'actions': [
                                            {
                                              'url': '/visits/delete',
                                              'type': 'submit',
                                              'method': 'POST',
                                              'event': 'click',
                                              'confirmation': {
                                                'title': 'VISIT_DELETE_TITLE',
                                                'content': 'VISIT_DELETE_MESSAGE',
                                                'confirm': 'GENERAL_CONFIRM',
                                                'cancel': 'GENERAL_CANCEL'
                                              }
                                            }
                                          ],
                                          'attributes': {
                                            'name': 'testBtn',
                                            'content': 'VISIT_BROWSE_DELETE',
                                            'value': 'a42d289767ef7dc72cac6ecf019c4f681a5156102280ab4d3ad290f863f9de933dab479322b944c6e5c1ead3cfa262dcd0aea35332c13008b635f055c2ce4b2d',
                                            'icon': 'trash-alt',
                                            'type': 'delete'
                                          },
                                          'styles': [],
                                          'children': []
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                      ]
                    },
                    {
                      'key': 'cards-browse-tab',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'LhGridCards'
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'complex-browse-visits2',
                          'type': 'LhGridCards',
                          'actions': [],
                          'attributes': {
                            'url': '/visits',
                            'disableMultiSelect': true,
                            'activeFilters': null
                          },
                          'styles': [],
                          'children': [
                            {
                              'key': 'row-0',
                              'type': 'LhGridRowCards',
                              'actions': [
                                []
                              ],
                              'attributes': [],
                              'styles': [],
                              'children': [
                                {
                                  'key': 'visit-visit-date-heading',
                                  'type': 'LhGridHeader',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'VISIT_DATE',
                                    'cellType': 'data',
                                    'checked': true,
                                    'showMobile': true
                                  },
                                  'styles': {
                                    'fontWeight': '900'
                                  },
                                  'children': []
                                },
                                {
                                  'key': 'visit-type-heading',
                                  'type': 'LhGridHeader',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'VISIT_TYPE',
                                    'cellType': 'data',
                                    'checked': true,
                                    'showMobile': true
                                  },
                                  'styles': {
                                    'fontWeight': '900'
                                  },
                                  'children': []
                                },
                                {
                                  'key': 'visit-duration-heading',
                                  'type': 'LhGridHeader',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'VISIT_DURATION',
                                    'cellType': 'data',
                                    'checked': true,
                                    'showMobile': true
                                  },
                                  'styles': {
                                    'fontWeight': '900'
                                  },
                                  'children': []
                                },
                                {
                                  'key': 'visit-nurse-heading',
                                  'type': 'LhGridHeader',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'VISIT_NURSE',
                                    'cellType': 'data',
                                    'checked': true,
                                    'showMobile': true
                                  },
                                  'styles': {
                                    'fontWeight': '900'
                                  },
                                  'children': []
                                },
                                {
                                  'key': 'visit-succession-heading',
                                  'type': 'LhGridHeader',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'VISIT_SUCCESSION',
                                    'cellType': 'data',
                                    'checked': true,
                                    'showMobile': true
                                  },
                                  'styles': {
                                    'fontWeight': '900'
                                  },
                                  'children': []
                                },
                                {
                                  'key': 'visit-action-heading',
                                  'type': 'LhGridHeader',
                                  'actions': [],
                                  'attributes': {
                                    'cellType': 'button',
                                    'class': 'cell-sticky'
                                  },
                                  'styles': {
                                    'fontWeight': '900'
                                  },
                                  'children': []
                                }
                              ]
                            },
                            {
                              'key': 'visit-browse-row-1',
                              'type': 'LhGridRowCards',
                              'actions': [
                                {
                                  'url': '/visits/detail/0713b703d83229ba8f4d932f14705313e7e4fabf4b7ffce40c953f66a441adabeb2c12fe263a78b651c722d69738b3a64824ea7bde6dd4b53523f796a19abfee',
                                  'type': 'update',
                                  'method': 'GET',
                                  'event': 'dblclick'
                                }
                              ],
                              'attributes': {
                                'guid': '0713b703d83229ba8f4d932f14705313e7e4fabf4b7ffce40c953f66a441adabeb2c12fe263a78b651c722d69738b3a64824ea7bde6dd4b53523f796a19abfee'
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'visit-date1',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '2020-03-10 10:00',
                                    'target': 'visit-visit-date-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-type1',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Type 2',
                                    'target': 'visit-type-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-duration1',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '40',
                                    'target': 'visit-duration-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-nurse-1',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Vereecken Bjorn',
                                    'target': 'visit-nurse-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-succession-1',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Low',
                                    'target': 'visit-succession-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-actions-1',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'target': 'visit-action-heading',
                                    'class': 'cell-sticky'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-edit-button-1',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/detail/0713b703d83229ba8f4d932f14705313e7e4fabf4b7ffce40c953f66a441adabeb2c12fe263a78b651c722d69738b3a64824ea7bde6dd4b53523f796a19abfee',
                                          'type': 'update',
                                          'method': 'GET',
                                          'event': 'click'
                                        }
                                      ],
                                      'attributes': {
                                        'content': 'VISIT_BROWSE_DETAIL',
                                        'icon': 'pencil-alt'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-delete-button1',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/delete',
                                          'type': 'submit',
                                          'method': 'POST',
                                          'event': 'click',
                                          'confirmation': {
                                            'title': 'VISIT_DELETE_TITLE',
                                            'content': 'VISIT_DELETE_MESSAGE',
                                            'confirm': 'GENERAL_CONFIRM',
                                            'cancel': 'GENERAL_CANCEL'
                                          }
                                        }
                                      ],
                                      'attributes': {
                                        'name': 'testBtn',
                                        'content': 'VISIT_BROWSE_DELETE',
                                        'value': '0713b703d83229ba8f4d932f14705313e7e4fabf4b7ffce40c953f66a441adabeb2c12fe263a78b651c722d69738b3a64824ea7bde6dd4b53523f796a19abfee',
                                        'icon': 'trash-alt',
                                        'type': 'delete'
                                      },
                                      'styles': [],
                                      'children': []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              'key': 'visit-browse-row-2',
                              'type': 'LhGridRowCards',
                              'actions': [
                                {
                                  'url': '/visits/detail/dac89db0956971c0a524de46bf120bd4e1995f6accbb7afe11d0afcf1daa36d5ce4743b502b11652d69a7ffa6c2d5fc9b5eaaf75f7ca7ad945fae7b0aa670331',
                                  'type': 'update',
                                  'method': 'GET',
                                  'event': 'dblclick'
                                }
                              ],
                              'attributes': {
                                'guid': 'dac89db0956971c0a524de46bf120bd4e1995f6accbb7afe11d0afcf1daa36d5ce4743b502b11652d69a7ffa6c2d5fc9b5eaaf75f7ca7ad945fae7b0aa670331'
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'visit-date2',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '2020-03-10 10:00',
                                    'target': 'visit-visit-date-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-type2',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Type 1',
                                    'target': 'visit-type-heading'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'indicator-tab',
                                      'type': 'LhColorIndicator',
                                      'actions': [],
                                      'attributes': {
                                        'value': '#23bac6'
                                      },
                                      'styles': [],
                                      'children': []
                                    }
                                  ]
                                },
                                {
                                  'key': 'visit-duration2',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '40',
                                    'target': 'visit-duration-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-nurse-2',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Maes Ben',
                                    'target': 'visit-nurse-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-succession-2',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Med',
                                    'target': 'visit-succession-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-actions-2',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'target': 'visit-action-heading',
                                    'class': 'cell-sticky'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-edit-button-2',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/detail/dac89db0956971c0a524de46bf120bd4e1995f6accbb7afe11d0afcf1daa36d5ce4743b502b11652d69a7ffa6c2d5fc9b5eaaf75f7ca7ad945fae7b0aa670331',
                                          'type': 'update',
                                          'method': 'GET',
                                          'event': 'click'
                                        }
                                      ],
                                      'attributes': {
                                        'content': 'VISIT_BROWSE_DETAIL',
                                        'icon': 'pencil-alt'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-delete-button2',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/delete',
                                          'type': 'submit',
                                          'method': 'POST',
                                          'event': 'click',
                                          'confirmation': {
                                            'title': 'VISIT_DELETE_TITLE',
                                            'content': 'VISIT_DELETE_MESSAGE',
                                            'confirm': 'GENERAL_CONFIRM',
                                            'cancel': 'GENERAL_CANCEL'
                                          }
                                        }
                                      ],
                                      'attributes': {
                                        'name': 'testBtn',
                                        'content': 'VISIT_BROWSE_DELETE',
                                        'value': 'dac89db0956971c0a524de46bf120bd4e1995f6accbb7afe11d0afcf1daa36d5ce4743b502b11652d69a7ffa6c2d5fc9b5eaaf75f7ca7ad945fae7b0aa670331',
                                        'icon': 'trash-alt',
                                        'type': 'delete'
                                      },
                                      'styles': [],
                                      'children': []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              'key': 'visit-browse-row-3',
                              'type': 'LhGridRowCards',
                              'actions': [
                                {
                                  'url': '/visits/detail/2d2ab92366e362cff121307d230d5468bcf258de163ffb2e9e760ad16a6153636a1c40fe0762dc69a217c9c385cb3eaa1e75bcc4564b012d4715a23fbe783bae',
                                  'type': 'update',
                                  'method': 'GET',
                                  'event': 'dblclick'
                                }
                              ],
                              'attributes': {
                                'guid': '2d2ab92366e362cff121307d230d5468bcf258de163ffb2e9e760ad16a6153636a1c40fe0762dc69a217c9c385cb3eaa1e75bcc4564b012d4715a23fbe783bae'
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'visit-date3',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '2020-02-16 11:02',
                                    'target': 'visit-visit-date-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-type3',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Type 1',
                                    'target': 'visit-type-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-duration3',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': null,
                                    'target': 'visit-duration-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-nurse-3',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'test verpleeger1',
                                    'target': 'visit-nurse-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-succession-3',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'High',
                                    'target': 'visit-succession-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-actions-3',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'target': 'visit-action-heading',
                                    'class': 'cell-sticky'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-edit-button-3',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/detail/2d2ab92366e362cff121307d230d5468bcf258de163ffb2e9e760ad16a6153636a1c40fe0762dc69a217c9c385cb3eaa1e75bcc4564b012d4715a23fbe783bae',
                                          'type': 'update',
                                          'method': 'GET',
                                          'event': 'click'
                                        }
                                      ],
                                      'attributes': {
                                        'content': 'VISIT_BROWSE_DETAIL',
                                        'icon': 'pencil-alt'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-delete-button3',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/delete',
                                          'type': 'submit',
                                          'method': 'POST',
                                          'event': 'click',
                                          'confirmation': {
                                            'title': 'VISIT_DELETE_TITLE',
                                            'content': 'VISIT_DELETE_MESSAGE',
                                            'confirm': 'GENERAL_CONFIRM',
                                            'cancel': 'GENERAL_CANCEL'
                                          }
                                        }
                                      ],
                                      'attributes': {
                                        'name': 'testBtn',
                                        'content': 'VISIT_BROWSE_DELETE',
                                        'value': '2d2ab92366e362cff121307d230d5468bcf258de163ffb2e9e760ad16a6153636a1c40fe0762dc69a217c9c385cb3eaa1e75bcc4564b012d4715a23fbe783bae',
                                        'icon': 'trash-alt',
                                        'type': 'delete'
                                      },
                                      'styles': [],
                                      'children': []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              'key': 'visit-browse-row-4',
                              'type': 'LhGridRowCards',
                              'actions': [
                                {
                                  'url': '/visits/detail/f6c80600e6c79693c20add70a4e65d32b8cfb8ac38c127237ee892d20eb05ac14e7269855abdebee2e16d38ba6baec6e9d6cb62306d6ff307e0d658939289a9a',
                                  'type': 'update',
                                  'method': 'GET',
                                  'event': 'dblclick'
                                }
                              ],
                              'attributes': {
                                'guid': 'f6c80600e6c79693c20add70a4e65d32b8cfb8ac38c127237ee892d20eb05ac14e7269855abdebee2e16d38ba6baec6e9d6cb62306d6ff307e0d658939289a9a'
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'visit-date4',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '2020-02-11 10:37',
                                    'target': 'visit-visit-date-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-type4',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Type 1',
                                    'target': 'visit-type-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-duration4',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '10',
                                    'target': 'visit-duration-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-nurse-4',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Vereecken Bjorn',
                                    'target': 'visit-nurse-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-succession-4',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'High',
                                    'target': 'visit-succession-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-actions-4',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'target': 'visit-action-heading',
                                    'class': 'cell-sticky'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-edit-button-4',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/detail/f6c80600e6c79693c20add70a4e65d32b8cfb8ac38c127237ee892d20eb05ac14e7269855abdebee2e16d38ba6baec6e9d6cb62306d6ff307e0d658939289a9a',
                                          'type': 'update',
                                          'method': 'GET',
                                          'event': 'click'
                                        }
                                      ],
                                      'attributes': {
                                        'content': 'VISIT_BROWSE_DETAIL',
                                        'icon': 'pencil-alt'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-delete-button4',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/delete',
                                          'type': 'submit',
                                          'method': 'POST',
                                          'event': 'click',
                                          'confirmation': {
                                            'title': 'VISIT_DELETE_TITLE',
                                            'content': 'VISIT_DELETE_MESSAGE',
                                            'confirm': 'GENERAL_CONFIRM',
                                            'cancel': 'GENERAL_CANCEL'
                                          }
                                        }
                                      ],
                                      'attributes': {
                                        'name': 'testBtn',
                                        'content': 'VISIT_BROWSE_DELETE',
                                        'value': 'f6c80600e6c79693c20add70a4e65d32b8cfb8ac38c127237ee892d20eb05ac14e7269855abdebee2e16d38ba6baec6e9d6cb62306d6ff307e0d658939289a9a',
                                        'icon': 'trash-alt',
                                        'type': 'delete'
                                      },
                                      'styles': [],
                                      'children': []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              'key': 'visit-browse-row-5',
                              'type': 'LhGridRowCards',
                              'actions': [
                                {
                                  'url': '/visits/detail/2971fe92682a2e1b001967a97c49d1034d06f9866b21b60d0577e699de7aaa6331a71a72e614d136bd7798136a73a3022d8dc25c38b5e0ac4dfbc150a5a38f8a',
                                  'type': 'update',
                                  'method': 'GET',
                                  'event': 'dblclick'
                                }
                              ],
                              'attributes': {
                                'guid': '2971fe92682a2e1b001967a97c49d1034d06f9866b21b60d0577e699de7aaa6331a71a72e614d136bd7798136a73a3022d8dc25c38b5e0ac4dfbc150a5a38f8a'
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'visit-date5',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '2020-02-11 10:32',
                                    'target': 'visit-visit-date-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-type5',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Type 1',
                                    'target': 'visit-type-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-duration5',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'test',
                                    'target': 'visit-duration-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-nurse-5',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Detemmerman Jordy',
                                    'target': 'visit-nurse-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-succession-5',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'High',
                                    'target': 'visit-succession-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-actions-5',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'target': 'visit-action-heading',
                                    'class': 'cell-sticky'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-edit-button-5',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/detail/2971fe92682a2e1b001967a97c49d1034d06f9866b21b60d0577e699de7aaa6331a71a72e614d136bd7798136a73a3022d8dc25c38b5e0ac4dfbc150a5a38f8a',
                                          'type': 'update',
                                          'method': 'GET',
                                          'event': 'click'
                                        }
                                      ],
                                      'attributes': {
                                        'content': 'VISIT_BROWSE_DETAIL',
                                        'icon': 'pencil-alt'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-delete-button5',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/delete',
                                          'type': 'submit',
                                          'method': 'POST',
                                          'event': 'click',
                                          'confirmation': {
                                            'title': 'VISIT_DELETE_TITLE',
                                            'content': 'VISIT_DELETE_MESSAGE',
                                            'confirm': 'GENERAL_CONFIRM',
                                            'cancel': 'GENERAL_CANCEL'
                                          }
                                        }
                                      ],
                                      'attributes': {
                                        'name': 'testBtn',
                                        'content': 'VISIT_BROWSE_DELETE',
                                        'value': '2971fe92682a2e1b001967a97c49d1034d06f9866b21b60d0577e699de7aaa6331a71a72e614d136bd7798136a73a3022d8dc25c38b5e0ac4dfbc150a5a38f8a',
                                        'icon': 'trash-alt',
                                        'type': 'delete'
                                      },
                                      'styles': [],
                                      'children': []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              'key': 'visit-browse-row-6',
                              'type': 'LhGridRowCards',
                              'actions': [
                                {
                                  'url': '/visits/detail/94dc999e6f5414b9689f02cd9ed44451694a3ab32f515281bdd2f93df0b0aac24d7cf1df506baedf63ef4426c9cb49ab74c4f571bd8e418920f2dcf5396e98e4',
                                  'type': 'update',
                                  'method': 'GET',
                                  'event': 'dblclick'
                                }
                              ],
                              'attributes': {
                                'guid': '94dc999e6f5414b9689f02cd9ed44451694a3ab32f515281bdd2f93df0b0aac24d7cf1df506baedf63ef4426c9cb49ab74c4f571bd8e418920f2dcf5396e98e4'
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'visit-date6',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '2020-02-04 10:43',
                                    'target': 'visit-visit-date-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-type6',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Type 2',
                                    'target': 'visit-type-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-duration6',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '101',
                                    'target': 'visit-duration-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-nurse-6',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Vereecken Bjorn',
                                    'target': 'visit-nurse-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-succession-6',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'High',
                                    'target': 'visit-succession-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-actions-6',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'target': 'visit-action-heading',
                                    'class': 'cell-sticky'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-edit-button-6',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/detail/94dc999e6f5414b9689f02cd9ed44451694a3ab32f515281bdd2f93df0b0aac24d7cf1df506baedf63ef4426c9cb49ab74c4f571bd8e418920f2dcf5396e98e4',
                                          'type': 'update',
                                          'method': 'GET',
                                          'event': 'click'
                                        }
                                      ],
                                      'attributes': {
                                        'content': 'VISIT_BROWSE_DETAIL',
                                        'icon': 'pencil-alt'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-delete-button6',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/delete',
                                          'type': 'submit',
                                          'method': 'POST',
                                          'event': 'click',
                                          'confirmation': {
                                            'title': 'VISIT_DELETE_TITLE',
                                            'content': 'VISIT_DELETE_MESSAGE',
                                            'confirm': 'GENERAL_CONFIRM',
                                            'cancel': 'GENERAL_CANCEL'
                                          }
                                        }
                                      ],
                                      'attributes': {
                                        'name': 'testBtn',
                                        'content': 'VISIT_BROWSE_DELETE',
                                        'value': '94dc999e6f5414b9689f02cd9ed44451694a3ab32f515281bdd2f93df0b0aac24d7cf1df506baedf63ef4426c9cb49ab74c4f571bd8e418920f2dcf5396e98e4',
                                        'icon': 'trash-alt',
                                        'type': 'delete'
                                      },
                                      'styles': [],
                                      'children': []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              'key': 'visit-browse-row-7',
                              'type': 'LhGridRowCards',
                              'actions': [
                                {
                                  'url': '/visits/detail/84aabd792ce097a55dfe25681b51de64619552199e68352ab134896d772bce1f75ff37a00fa172cf2e883905452725f74b7c7d81a3113d23ce2792a36817ae39',
                                  'type': 'update',
                                  'method': 'GET',
                                  'event': 'dblclick'
                                }
                              ],
                              'attributes': {
                                'guid': '84aabd792ce097a55dfe25681b51de64619552199e68352ab134896d772bce1f75ff37a00fa172cf2e883905452725f74b7c7d81a3113d23ce2792a36817ae39'
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'visit-date7',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '2020-02-03 10:55',
                                    'target': 'visit-visit-date-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-type7',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Type 1',
                                    'target': 'visit-type-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-duration7',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': null,
                                    'target': 'visit-duration-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-nurse-7',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Detemmerman Jordy',
                                    'target': 'visit-nurse-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-succession-7',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'High',
                                    'target': 'visit-succession-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-actions-7',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'target': 'visit-action-heading',
                                    'class': 'cell-sticky'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-edit-button-7',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/detail/84aabd792ce097a55dfe25681b51de64619552199e68352ab134896d772bce1f75ff37a00fa172cf2e883905452725f74b7c7d81a3113d23ce2792a36817ae39',
                                          'type': 'update',
                                          'method': 'GET',
                                          'event': 'click'
                                        }
                                      ],
                                      'attributes': {
                                        'content': 'VISIT_BROWSE_DETAIL',
                                        'icon': 'pencil-alt'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-delete-button7',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/delete',
                                          'type': 'submit',
                                          'method': 'POST',
                                          'event': 'click',
                                          'confirmation': {
                                            'title': 'VISIT_DELETE_TITLE',
                                            'content': 'VISIT_DELETE_MESSAGE',
                                            'confirm': 'GENERAL_CONFIRM',
                                            'cancel': 'GENERAL_CANCEL'
                                          }
                                        }
                                      ],
                                      'attributes': {
                                        'name': 'testBtn',
                                        'content': 'VISIT_BROWSE_DELETE',
                                        'value': '84aabd792ce097a55dfe25681b51de64619552199e68352ab134896d772bce1f75ff37a00fa172cf2e883905452725f74b7c7d81a3113d23ce2792a36817ae39',
                                        'icon': 'trash-alt',
                                        'type': 'delete'
                                      },
                                      'styles': [],
                                      'children': []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              'key': 'visit-browse-row-8',
                              'type': 'LhGridRowCards',
                              'actions': [
                                {
                                  'url': '/visits/detail/a42d289767ef7dc72cac6ecf019c4f681a5156102280ab4d3ad290f863f9de933dab479322b944c6e5c1ead3cfa262dcd0aea35332c13008b635f055c2ce4b2d',
                                  'type': 'update',
                                  'method': 'GET',
                                  'event': 'dblclick'
                                }
                              ],
                              'attributes': {
                                'guid': 'a42d289767ef7dc72cac6ecf019c4f681a5156102280ab4d3ad290f863f9de933dab479322b944c6e5c1ead3cfa262dcd0aea35332c13008b635f055c2ce4b2d'
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'visit-date8',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '2020-01-10 10:00',
                                    'target': 'visit-visit-date-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-type8',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Type 3',
                                    'target': 'visit-type-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-duration8',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': '40',
                                    'target': 'visit-duration-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-nurse-8',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Maes Ben',
                                    'target': 'visit-nurse-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-succession-8',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'content': 'Med',
                                    'target': 'visit-succession-heading'
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'visit-actions-8',
                                  'type': 'LhGridCell',
                                  'actions': [],
                                  'attributes': {
                                    'target': 'visit-action-heading',
                                    'class': 'cell-sticky'
                                  },
                                  'styles': [],
                                  'children': [
                                    {
                                      'key': 'visit-edit-button-8',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/detail/a42d289767ef7dc72cac6ecf019c4f681a5156102280ab4d3ad290f863f9de933dab479322b944c6e5c1ead3cfa262dcd0aea35332c13008b635f055c2ce4b2d',
                                          'type': 'update',
                                          'method': 'GET',
                                          'event': 'click'
                                        }
                                      ],
                                      'attributes': {
                                        'content': 'VISIT_BROWSE_DETAIL',
                                        'icon': 'pencil-alt'
                                      },
                                      'styles': [],
                                      'children': []
                                    },
                                    {
                                      'key': 'visit-delete-button8',
                                      'type': 'LhIconButton',
                                      'actions': [
                                        {
                                          'url': '/visits/delete',
                                          'type': 'submit',
                                          'method': 'POST',
                                          'event': 'click',
                                          'confirmation': {
                                            'title': 'VISIT_DELETE_TITLE',
                                            'content': 'VISIT_DELETE_MESSAGE',
                                            'confirm': 'GENERAL_CONFIRM',
                                            'cancel': 'GENERAL_CANCEL'
                                          }
                                        }
                                      ],
                                      'attributes': {
                                        'name': 'testBtn',
                                        'content': 'VISIT_BROWSE_DELETE',
                                        'value': 'a42d289767ef7dc72cac6ecf019c4f681a5156102280ab4d3ad290f863f9de933dab479322b944c6e5c1ead3cfa262dcd0aea35332c13008b635f055c2ce4b2d',
                                        'icon': 'trash-alt',
                                        'type': 'delete'
                                      },
                                      'styles': [],
                                      'children': []
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },

                    //Test form submit with all type of inputs
                    {
                      'key': 'all-inputs-test',
                      'type': 'LhDiv',
                      'actions': [],
                      'attributes': {
                        'label': 'All inputs test',
                        'active': true
                      },
                      'styles': [],
                      'children': [
                        {
                          'key': 'form-test-inputs',
                          'type': 'LhForm',
                          'actions': [{ 'url': '/testing', 'type': 'update', 'method': 'POST', 'event': 'submit' }],
                          'attributes': {},
                          'styles': {},
                          'children': [
                            {
                              'key': 'visitorder-update-button',
                              'type': 'LhButton',
                              'actions': [{
                                'url': '/testing',
                                'type': 'submit',
                                'method': 'POST',
                                'event': 'click'
                              }],
                              'attributes': {
                                'form': 'form-test-inputs',
                                'content': 'Submit',
                                'name': 'visitorder-add-edit-button',
                                'type': 'submit',
                                'class': 'primary',
                                'closeOnSave': true
                              },
                              'styles': [],
                              'children': []
                            },
                            {
                              'key': 'opening-test',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'businesshour',
                                'label': 'businesshour-input',
                                'name': 'businesshour-input',
                                'direction': 'horizontal',
                                'days': ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
                                'value': {
                                  'AM': ['true', 'true', 'true', 'true', 'true', 'false', 'false'],
                                  'PM': ['true', 'false', 'true', 'true', 'true', 'false', 'false'],
                                  'Custom': ['8u-15u', '8u-15u', '8u-15u', '8u-15u', '8u-15u', '-', '-'],
                                  'Custom2': ['8u-15u', '8u-15u', '8u-15u', '8u-15u', '8u-15u', '-', '-'],
                                },
                                // 'validation': [{
                                //   'type': 'required',
                                //   'translation_key': 'GENERAL_VALIDATION_REQUIRED',
                                //   'translation_key_custom': null
                                // }]
                              },
                              'styles': {},
                              'children': []
                            }, {
                              'key': 'pharmacyToInvoiceSearch',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'autosuggest',
                                'label': 'VISITORDER_PHARMACYTOINVOICEID',
                                'name': 'pharmacyToInvoiceSearch',
                                'id': 'visitorder-pharmacyToInvoiceId-cru',
                                'value': '893cb652b3951020c712c2cc76d97f348e79d3fe72dcbce88e511050dea6b384bf14195e609ff2b89c441b4c9ed1e02a3697e20a66b1727883b16fe3249b0b29',
                                'placeholder': 'Pharmacie de Chailly SA',
                                'url': '\/pharmacies\/search\/pharmacySearch',
                                'disabled': false,
                                'labelCols': 4,
                                'labelIcon': {
                                  icon: ['fal', 'clipboard-list'],
                                  tooltip: 'It\'s tea-time!'
                                }
                              },
                              'styles': [],
                              'children': []
                            }, {
                              'key': 'visitorder-edit-form-row1',
                              'type': 'LhRow',
                              'actions': [],
                              'attributes': [],
                              'styles': [],
                              'children': [{
                                'key': 'visitorder-edit-visit-date-col4',
                                'type': 'LhCol',
                                'actions': [],
                                'attributes': { 'cols': 6 },
                                'styles': [],
                                'children': [{
                                  'key': 'visitorder-date',
                                  'type': 'LhInput',
                                  'actions': [],
                                  'attributes': {
                                    'type': 'date',
                                    'label': 'VISITORDER_DATE',
                                    'name': 'date',
                                    'id': 'visitorder-date-cru',
                                    'value': '2021-01-13',
                                    'disabled': false,
                                    'validation': [{
                                      'type': 'required',
                                      'translation_key': 'GENERAL_VALIDATION_REQUIRED',
                                      'translation_key_custom': null
                                    }]
                                  },
                                  'styles': [],
                                  'children': []
                                }]
                              }, {
                                'key': 'visitorder-edit-visit-date-col',
                                'type': 'LhCol',
                                'actions': [],
                                'attributes': { 'cols': 6 },
                                'styles': [],
                                'children': [{
                                  'key': 'visitorder-type',
                                  'type': 'LhInput',
                                  'actions': [],
                                  'attributes': {
                                    'type': 'text',
                                    'label': 'VISITORDER_TYPE',
                                    'name': 'type',
                                    'id': 'visitorder-type-cru',
                                    'value': 'ORDER',
                                    'disabled': false
                                  },
                                  'styles': [],
                                  'children': []
                                }]
                              }]
                            }, {
                              'key': 'wholesalerSearch', 'type': 'LhInput', 'actions': [], 'attributes': {
                                'type': 'select',
                                'extraInfo': {
                                  'url': '/test',
                                  'content': ['Test pharmacy', 'Address line 1', 'Address line 2']
                                },
                                'label': 'VISITORDER_WHOLESALERID',
                                'name': 'wholesalerSearch',
                                'id': 'visitorder-wholesalerId-cru',
                                'value': '29db66a785a0a80d00e7135102d639a0a25121fd10d8ae83fcb7dcfed27126cffc6b2e9501645404c970b790c50f646e9f49620662851bf40495cec5f08b6e69',
                                'content': [{
                                  'text': 'GENERAL_CHOOSE',
                                  'value': ''
                                }, {
                                  'value': '29db66a785a0a80d00e7135102d639a0a25121fd10d8ae83fcb7dcfed27126cffc6b2e9501645404c970b790c50f646e9f49620662851bf40495cec5f08b6e69',
                                  'text': 'Amedis(Puidoux)'
                                }, {
                                  'value': '5dd2fde377f7f4de48c978c8df78be605a8652f05aab96805942876f9f1c23b68135d27eefb1c3ccda7eaf9590e13dae35bd5f6bf6746d6f93c55890b6ebe6ff',
                                  'text': 'Amedis(Unterentfelden)'
                                }, {
                                  'value': 'dec2069cc5b97650f0e032beeef7afbd24e72d88292094aafdc36f581d91901f13f3427ecb09afcb416d8102540ce7d2ab0242a7aeeb808360fd612e6a5bdf3d',
                                  'text': 'Galexis (Ecublens)'
                                }],
                                'disabled': false,
                                'validation': [{
                                  'type': 'required',
                                  'translation_key': 'GENERAL_VALIDATION_REQUIRED',
                                  'translation_key_custom': null
                                }],
                                'labelCols': 12
                              },
                              'styles': [],
                              'children': []
                            },
                            {
                              'key': 'simple-collapse', 'type': 'LhSimpleCollapse', 'actions': [], 'attributes': {
                                'id': 'visitorder-wholesalerId-cru',
                                icons: {
                                  open: ['fas', 'caret-down'],
                                  closed: ['fas', 'caret-right']
                                },
                                text: {
                                  closed: 'Show more',
                                  open: 'Show less'
                                },
                              },
                              'styles': [],
                              'children': [
                                {
                                  'key': 'pharmacyToInvoiceSearch3',
                                  'type': 'LhInput',
                                  'actions': [],
                                  'attributes': {
                                    'type': 'text',
                                    'label': 'VISITORDER_PHARMACYTOINVOICEID3',
                                    'name': 'pharmacyToInvoiceSearch3',
                                    'id': 'visitorder-pharmacyToInvoiceId-cru3',
                                    'value': '',
                                    'placeholder': '',
                                    'disabled': false,
                                    'labelCols': 12
                                  },
                                  'styles': [],
                                  'children': []
                                },
                                {
                                  'key': 'pharmacyToInvoiceSearch2',
                                  'type': 'LhInput',
                                  'actions': [],
                                  'attributes': {
                                    'type': 'text',
                                    'label': 'VISITORDER_PHARMACYTOINVOICEID2',
                                    'name': 'pharmacyToInvoiceSearch2',
                                    'id': 'visitorder-pharmacyToInvoiceId-cru2',
                                    'value': '',
                                    'placeholder': '',
                                    'disabled': false,
                                    'labelCols': 12
                                  },
                                  'styles': [],
                                  'children': []
                                }
                              ]
                            },
                            {
                              'key': 'wholesalerSearch-2', 'type': 'LhInput', 'actions': [], 'attributes': {
                                'type': 'select',
                                'label': 'grouped select',
                                'name': 'wholesalerSearch-grouped',
                                'id': 'visitorder-wholesalerId-cru',
                                'value': 'guid1',
                                'content':  [
                                  { value: 'a', text: 'This is First option - a' },
                                  {
                                    text: 'Group1 options',
                                    content: [
                                      { value: 'guid1', text: 'Option with value - guid1' },
                                      { value: 'guid2', text: 'Another option with value - guid2' }
                                    ]
                                  },
                                  {
                                    text: 'Group2 options',
                                    content: [
                                      { value: 'guid3', text: 'Option2 with value - guid3' },
                                      {
                                        text: 'Group2 subgroup',
                                        content: [
                                          { value: 'guid4', text: 'Not supported - guid4' },
                                          { value: 'guid5', text: 'Not supported - guid5' }
                                        ]
                                      },
                                    ]
                                  },
                                ],
                                'disabled': false,
                                'validation': [{
                                  'type': 'required',
                                  'translation_key': 'GENERAL_VALIDATION_REQUIRED',
                                  'translation_key_custom': null
                                }],
                                'labelCols': 12
                              }, 'styles': [], 'children': []
                            }, {
                              'key': 'visitorder-postponeDeliveryUntil',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'time',
                                'label': 'TIME',
                                'name': 'time',
                                'id': 'visitorder-postponeDeliveryUntil-time',
                                'value': '12:30',
                                'disabled': false
                              },
                              'styles': [],
                              'children': []
                            },
                            {
                              'key': 'visitorder-edit-form-row3',
                              'type': 'LhRow',
                              'actions': [],
                              'attributes': [],
                              'styles': [],
                              'children': [{
                                'key': 'visitorder-edit-history- col',
                                'type': 'LhCol',
                                'actions': [],
                                'attributes': { 'cols': 6 },
                                'styles': [],
                                'children': [{
                                  'key': 'visitorder-HistoryOnly',
                                  'type': 'LhInput',
                                  'actions': [],
                                  'attributes': {
                                    'type': 'checkbox',
                                    'label': 'VISITORDER_HISTORYONLY',
                                    'name': 'HistoryOnly',
                                    'id': 'visitorder-HistoryOnly-cru',
                                    'disabled': false,
                                    'value': ['history-checked3']
                                  },
                                  'styles': [],
                                  'children': [{
                                    'key': 'visitorder-HistoryOnly',
                                    'type': '',
                                    'actions': [],
                                    'attributes': {
                                      'content': 'History item',
                                      'value': 'history-checked'
                                    },
                                    'styles': [],
                                    'children': []
                                  },
                                    {
                                      'key': 'visitorder-HistoryOnly',
                                      'type': '',
                                      'actions': [],
                                      'attributes': {
                                        'content': 'History item2',
                                        'value': 'history-checked3'
                                      },
                                      'styles': [],
                                      'children': []
                                    }]
                                }]
                              }]
                            },
                            {
                              'key': 'visitorder-edit-form-row3b',
                              'type': 'LhRow',
                              'actions': [],
                              'attributes': [],
                              'styles': [],
                              'children': [{
                                'key': 'visitorder-edit-history-col2',
                                'type': 'LhCol',
                                'actions': [],
                                'attributes': { 'cols': 6 },
                                'styles': [],
                                'children': [{
                                  'key': 'visitorder-HistoryOnly',
                                  'type': 'LhInput',
                                  'actions': [],
                                  'attributes': {
                                    'type': 'checkbox',
                                    'label': 'Single checkbox test',
                                    'name': 'SingleCheckbox',
                                    'id': 'visitorder-singelchecktest',
                                    'disabled': false,
                                    'content': 'Single checkbox',
                                    'value': 'history-checked-single'
                                  },
                                  'styles': [],
                                  'children': []
                                }]
                              }]
                            },
                            {
                              'key': 'visitorder-sentDaterange',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'daterange',
                                'label': 'VISITORDER_SENTDATE_RANGE',
                                'name': 'sentDateRange',
                                'id': 'visitorder-sentDaterange',
                                'value': {
                                  'start': '2020-03-13T00:00:00',
                                  'end': '2021-11-25T00:00:00'
                                },
                              },
                              'styles': [],
                              'children': []
                            }, {
                              'key': 'visitorder-memo',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'textarea',
                                'label': 'VISITORDER_MEMO',
                                'name': 'memo',
                                'id': 'visitorder-memo-cru',
                                'value': 'textarea value',
                                'disabled': false
                              },
                              'styles': [],
                              'children': []
                            }, {
                              'key': 'visitorder-guid',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'hidden',
                                'name': 'guid',
                                'id': 'guid',
                                'value': 'd0e95822f99c8580ac299b89e69d69569107db9b2e3fd8e5b9d159a5afbc89deba149a1f19a11733a44da17cea9d8e9dc97e5104fce3ab5d7db37806d4fa5137'
                              },
                              'styles': [],
                              'children': []
                            },
                            {
                              'key': 'visitorder-memo-upload',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'file',
                                'label': 'VISITORDER_MEMO_FILE',
                                'name': 'memo-file',
                                'id': 'visitorder-memo-file',
                                'value': null,
                                'disabled': false
                              },
                              'styles': [],
                              'children': []
                            },
                            {
                              'key': 'pharmacy-customDiscountActive',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'radio',
                                'name': 'customDiscountActive',
                                'id': 'pharmacy-customDiscountActive-cru',
                                'value': '0',
                                'disabled': false,
                                'stacked': true
                              },
                              'styles': [],
                              'children': [{
                                'key': null,
                                'type': null,
                                'actions': [],
                                'attributes': { 'value': '0', 'content': 'PHARMACY_CUSTOMDISCOUNTINACTIVE' },
                                'styles': [],
                                'children': []
                              }, {
                                'key': null,
                                'type': null,
                                'actions': [],
                                'attributes': { 'value': '1', 'content': 'PHARMACY_CUSTOMDISCOUNTACTIVE' },
                                'styles': [],
                                'children': []
                              }]
                            },
                            {
                              'key': 'unavailabilitytype-colour',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'colorpicker',
                                'label': 'UNAVAILABILITYTYPE_COLOUR',
                                'name': 'colour',
                                'id': 'unavailabilitytype-colour-cru',
                                'value': '#B03232',
                                'disabled': false,
                                'focusOnLoad': true,
                                'validation': [{
                                  'type': 'required',
                                  'translation_key': 'GENERAL_VALIDATION_REQUIRED',
                                  'translation_key_custom': null
                                }]
                              },
                              'styles': [],
                              'children': []
                            },
                            {
                              'key': 'unavailabilitytype-mail',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'inputtags',
                                'label': 'EMAILS',
                                'name': 'mailtags',
                                'id': 'unavailabilitytype-colour-mail',
                                'value': ['test@mail.com', 'test2@mail.com'],
                                'disabled': false,
                                'validation': [{
                                  'type': 'email',
                                  'translation_key': 'INVALID_EMAIL',
                                  'translation_key_custom': null
                                }]
                              },
                              'styles': [],
                              'children': []
                            },
                            {
                              'key': 'unavailabilitytype-range',
                              'type': 'LhInput',
                              'actions': [],
                              'attributes': {
                                'type': 'range',
                                'label': 'RANGE',
                                'name': 'range',
                                'id': 'unavailabilitytype-range',
                                'value': '55',
                                'disabled': false,
                                'validation': [{
                                  'type': 'required',
                                  'translation_key': 'INVALID_EMAIL',
                                  'translation_key_custom': null
                                }]
                              },
                              'styles': [],
                              'children': []
                            }
                          ]
                        },
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      }
    },
    computed: {
      main () {
        return this.$store.getters['getMainJSON']
      }
    },
    mounted () {
      this.$store.commit('setMainJSON', this.message)
    }
  }
</script>

<style lang="scss" scoped>
  .home {
    display: flex;
    min-height: 100vh;
  }

  tbody.collapse.in {
    display: table-row-group;
  }
</style>

