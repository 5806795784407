<template>
    <ValidationObserver :ref="'observer' + obj.key">
        <div class="form-step-order">
            <b-row align-v="center" class="mb-2">
                <b-col md="6">
                    <lh-input @handleSearchedItem="pharmacySearchedItem" :obj="inputs.pharmacySearch" :parentKey="obj.key"></lh-input>
                </b-col>
                <b-col md="6" v-if="showRepSearch">
                    <lh-input @handleSearchedItem="repSearchedItem" :obj="inputs.repSearch" :parentKey="obj.key"></lh-input>
                </b-col>
            </b-row>
            <b-row class="filter-row">
                <b-col md="6">
                    <lh-input :obj="inputs.freeProductSearch" :parentKey="obj.key"></lh-input>
                </b-col>
            </b-row>
            <div class="order-products row">

                <!-- Search results -->
                <b-col md="6" class="results">
                    <div v-if="!this.pharmacySelected" class="warning">{{ $t('FREE_PRODUCT_SELECT_PHARMACY_FIRST') }}</div>
                    <template v-else-if="searchResults.length > 0">
                        <lh-product-detail
                            v-for="result in searchResults"
                            :obj="result"
                            :currency="currency"
                            :isFreeProduct="true"
                            :notAddable="!!isInBasket(result.guid)"
                            @add-product="addToBasket"
                            @edit-product="getProductDetails"
                            @checkBudget="checkBudget"
                            @update-totals="updateBasketPrices"
                            :class="[{selected: result.guid === selectedProduct}]"
                            :basket.sync="basket"
                        ></lh-product-detail>
                    </template>
                    <div v-else-if="this.searchLoading" class="placeholder">{{ $t('Loading') }}</div>
                    <div v-else class="placeholder">{{ $t('No results') }}</div>
                </b-col>

                <!-- Basket -->
                <b-col md="6" class="basket d-flex flex-column">
                    <div class="product-wrapper">
                        <transition-group name="list">
                            <lh-product-detail
                                v-for="product in basket.products"
                                :obj="product"
                                :inBasket="true"
                                :currency="currency"
                                :isFreeProduct="true"
                                @edit-product="getProductDetails"
                                @remove-product="removeProduct"
                                @update-totals="updateBasketPrices"
                                @checkBudget="checkBudget"
                                :key="product.guid"
                                :basket.sync="basket"
                            ></lh-product-detail>
                        </transition-group>
                    </div>
                    <b-row class="basket__price pt-1 border-top mt-auto">
                        <b-col>
                            <b-row align-h="between">
                                <b-col>{{ $t('FREE_PRODUCT_PHARMACY_BUDGET') }}:</b-col>
                                <b-col class="text-right">
                                    <span v-if="pharmacyFreeProductBudget.attributes.content">{{ currency }}{{ roundPrices(pharmacyFreeProductBudget.attributes.content) }}</span>
                                    <span v-else>0</span>
                                </b-col>
                            </b-row>
                            <b-row align-h="between">
                                <b-col>{{ $t('FREE_PRODUCT_TOTAL_REGISTRATION') }}:</b-col>
                                <b-col class="text-right" v-if="basket.prices">
                                    <template v-if="basket.prices !== null">{{ currency }}{{ roundPrices(basket.prices.value ? basket.prices.value : basket.prices) }}</template>
                                </b-col>
                            </b-row>
                            <b-row align-h="between" class="font-weight-bold mb-3">
                                <b-col>{{ $t('FREE_PRODUCT_TOTAL_BUDGET_LEFT') }}:</b-col>
                                <b-col class="text-right" v-if="basket.prices">
                                    <template v-if="basket.prices !== null">{{ currency }}{{ roundPrices(pharmacyFreeProductBudget.attributes.content - basket.prices) }}</template>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'
    import { eventBus } from '@/services/event-bus'
    import { mapActions, mapState } from 'vuex'
    import debounce from 'lodash/debounce'

    export default {
        name: 'LhFormFreeOrder',
        components: {
            ValidationObserver
        },
        props: {
            obj: {
                type: Object,
                required: true
            },
        },
        data () {
            return {
                notifications: [],
                searchResults: [],
                productDetails: {},
                selectedProduct: '',
                baseDiscount: 0,
                productAmount: 1,
                productAmountError: false,
                productDiscount: 0,
                modalDiscount: 0,
                pharmacySearch: '',
                pharmacySelected: (this.obj.attributes.pharmacy ? true : false),
                showRepSearch: false,
                repSearch: '',
                freeProductSearch: '',
                searchLoading: false,
                productFilterSelect: '',
                editingProduct: false,
                currency: '€',
                filtering: false,
                lastProductValues: {
                    units: 0,
                    discount: 0
                },
                loadingProduct: false,
                baseDiscountInit: false,
                pharmacyFreeProductBudget: {
                    attributes: {
                        content: (this.obj.attributes.pharmacy ? this.obj.attributes.pharmacy.budget : 0),
                    },
                },
                inputs: {
                    pharmacySearch: {
                        'key': 'form-step-pharmacy-search',
                        'type': 'LhInput',
                        'actions': [],
                        'attributes': {
                            'type': 'autosuggest',
                            'name': 'pharmacySearch',
                            'id': 'form-step-pharmacy-search',
                            'value': (this.obj.attributes.pharmacy ? this.obj.attributes.pharmacy.guid : ''),
                            'placeholder': (this.obj.attributes.pharmacy ? this.obj.attributes.pharmacy.placeholder : 'Search'),
                            'disabled': (this.obj.attributes.pharmacy ? this.obj.attributes.pharmacy.disabled : false),
                            'label': 'FREE_PRODUCT_PHARMACY',
                            'url': '\/pharmacies\/search\/free',
                            'validation': [
                                {
                                    'translation_key': 'GENERAL_VALIDATION_REQUIRED',
                                    'translation_key_custom': null,
                                    'type': 'required'
                                }
                            ]
                        },
                        'styles': [],
                        'children': []
                    },
                    repSearch: {
                        'key': 'form-step-rep-search',
                        'type': 'LhInput',
                        'actions': [],
                        'attributes': {
                            'type': 'autosuggest',
                            'name': 'repSearch',
                            'id': 'form-step-rep-search',
                            'value': (this.obj.attributes.representative ? this.obj.attributes.representative.guid : ''),
                            'placeholder': (this.obj.attributes.representative ? this.obj.attributes.representative.placeholder : 'Search'),
                            'disabled': (this.obj.attributes.representative ? this.obj.attributes.representative.disabled : false),
                            'label': 'FREE_PRODUCT_REPRESENTATIVE',
                            'url': '\/representatives\/search\/free',
                            'validation': [
                                {
                                    'translation_key': 'GENERAL_VALIDATION_REQUIRED',
                                    'translation_key_custom': null,
                                    'type': 'required'
                                }
                            ]
                        },
                        'styles': [],
                        'children': []
                    },
                    freeProductSearch: {
                        'key': 'form-step-free-product-search',
                        'type': 'LhInput',
                        'actions': [],
                        'attributes': {
                            'type': 'text',
                            'name': 'freeProductSearch',
                            'id': 'form-step-free-product-search',
                            'value': '',
                            'placeholder': 'Search',
                            'disabled': (this.obj.attributes.pharmacy ? false : true),
                        },
                        'styles': [],
                        'children': []
                    },
                    filterSelect: {
                        'key': 'form-step-product-filter-select',
                        'type': 'LhInput',
                        'actions': [],
                        'attributes': {
                            'type': 'select',
                            'name': 'productFilterSelect',
                            'id': 'product-filterselect',
                            'value': '',
                            'content': [{
                                'text': 'GENERAL_NO_OPTIONS',
                                'value': ''
                            }],
                            'disabled': false,
                        }, 'styles': [], 'children': []
                    }
                },
                buttons: {
                    recentProducts: {
                        'key': 'recent-products-button',
                        'type': 'LhButton',
                        'actions': [
                            {
                                event: 'click',
                                method: 'get-recent-products',
                                type: 'emit',
                                url: ''
                            }
                        ],
                        'attributes': {
                            'content': 'VISITORDER_RECENT',
                            'name': 'recent-products-button',
                            icon: 'history',
                            disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                    },
                    topProducts: {
                        'key': 'top-products-button',
                        'type': 'LhButton',
                        'actions': [
                            {
                                event: 'click',
                                method: 'get-top-products',
                                type: 'emit',
                                url: ''
                            }
                        ],
                        'attributes': {
                            'content': 'VISITORDER_TOP',
                            'name': 'top-products-button',
                            icon: 'trophy',
                            disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                    },
                    setDiscount: {
                        'key': 'set-basket-discount-button',
                        'type': 'LhButton',
                        'actions': [],
                        'attributes': {
                            'content': 'Set discount',
                            'name': 'set-discount-button',
                            'type': 'emit',
                            icon: 'percent',
                            disabled: this.loadingJSON,
                        },
                        'styles': [],
                        'children': []
                    },
                },
                basket: {},
                initialValues: {}
            }
        },
        computed: {
            ...mapState({
                loadingJSON: 'loadingJSON'
            }),
            product () {
                return this.productDetails[this.selectedProduct]
            },
            calcDiscount () {
                if (this.product) {
                    return parseFloat(this.baseDiscount) + parseFloat(this.product.discount) + '%'
                }
            },
            calcModalDiscount () {
                let sum = parseFloat(this.baseDiscount) + parseFloat(this.modalDiscount)
                return sum > 0 ? sum + '%' : 0 + '%'
            },
            calcProductPrice () {
                if (this.product) {
                    let basePrice = parseFloat(this.product.unitPrice) * parseFloat(this.product.units)
                    let discount = basePrice * ((parseFloat(this.product.discount) + parseFloat(this.baseDiscount)) / 100)
                    let totalPrice = basePrice - discount
                    return {
                        basePrice: basePrice.toFixed(2),
                        discount: discount.toFixed(2),
                        totalPrice: totalPrice.toFixed(2)
                    }
                }
            },
            discountError () {
                if (this.product.maximumDiscount > 0) {
                    if (this.product?.discount) return (Math.abs(this.product.discount) + Math.abs(this.baseDiscount)) > Math.abs(this.obj.attributes.maxDiscount) || (Math.abs(this.product.discount) + Math.abs(this.baseDiscount)) > Math.abs(this.product.maximumDiscount)

                    if (this.baseDiscount) return Math.abs(this.baseDiscount) > Math.abs(this.product.maximumDiscount) || Math.abs(this.baseDiscount) > Math.abs(this.obj.attributes.maxDiscount)
                }

                if (this.product?.discount) return (Math.abs(this.product.discount) + Math.abs(this.baseDiscount)) > Math.abs(this.obj.attributes.maxDiscount)

                if (this.baseDiscount) return Math.abs(this.baseDiscount) > Math.abs(this.obj.attributes.maxDiscount)
            },
            basketEmpty () {
                return this.basket.products ? this.basket?.products.length === 0 : true
            },
            popup () {
                return this.$store.getters.isObjInsidePopup(this.obj.key)
            },
            formItems () {
                return this.$store.getters.getFormFilledDataByKey(this.obj.key)
            }
        },
        methods: {
            ...mapActions({
                post: 'postLocal'
            }),
            searchPharmacies (payload) {
                //
            },
            pharmacySearchedItem (searchedItem) {
                if(searchedItem && searchedItem.value){
                    this.inputs.freeProductSearch.attributes.disabled = false
                    this.inputs.pharmacySearch.attributes.value = searchedItem.value
                }
                if(searchedItem.componentReplace && searchedItem.componentReplace.pharmacyFreeProductBudget){
                    this.pharmacyFreeProductBudget = searchedItem.componentReplace.pharmacyFreeProductBudget
                }
            },
            repSearchedItem (searchedItem) {
                if(searchedItem && searchedItem.value){
                    this.inputs.repSearch.attributes.value = searchedItem.value
                }
            },
            searchReps (payload) {
                //
            },
            searchFreeProducts (payload) {
                // clear search results
                this.searchResults = []
                // make api call to get product list based on params
                let data = {
                    url: `free_products/search/wizard`,
                    payload
                }
                // set loader
                this.searchLoading = true
                this.post(data).then((response) => {
                    this.searchResults = response.data
                    console.log(this.basket)
                }).finally(() => {
                    this.searchLoading = false
                    this.filtering = false
                })
            },
            getProductDetails (product) {
                // show product in details column and add to list of product details
                // when editing product already in basket
                let inBasket = this.isInBasket(product.guid)
                if (inBasket && product.units) inBasket.units = product.units
                if (inBasket) this.$set(this.productDetails, product.guid, Object.assign({}, inBasket))
                // when editing product from basket
                if (product.hasOwnProperty('totalValue')) this.$set(this.productDetails, product.guid, Object.assign({}, product))
                this.editingProduct = (inBasket || product.hasOwnProperty('totalValue'))
                if (!this.productDetails[product.guid]) {
                    // add product to list if it doesn't exist yet
                    this.$set(this.productDetails, product.guid, product)
                    this.$set(this.productDetails[product.guid], 'units', product.units ? product.units : this.lastProductValues.units)
                }
                this.selectedProduct = product.guid
            },
            removeProduct (productToDelete) {
                this.basket.products = this.basket.products.filter(function( product ) {
                    let matchGuid = productToDelete.productGuid ? productToDelete.productGuid : productToDelete.guid
                    return product.productGuid !== matchGuid
                });
                this.$set(this, 'basket', this.basket)
            },
            handleInput (event) {
                //Handle changes
                this.handleChange(event.event, event.value, event.name, event.key, event.selectValue)

                let val = Math.abs(parseFloat(event.value))
                if (event.name === 'pharmacySearch' || event.name === 'repSearch' || event.name === 'freeProductSearch' || event.name === 'productFilterSelect') {
                    this[event.name] = event.value
                    return
                }

                if (val > 0) {
                    this[event.name] = val
                    this.productAmountError = this.product.units <= 0
                    return
                }
                this[event.name] = event.value.length ? event.value : 0
                this.productAmountError = this.product.units <= 0
            },
            handleChange (event, value, inputName, inputKey, selectValue) {
                let inputs = this.formItems ? this.formItems.inputs : {}
                let oldValue = this.initialValues[inputKey]
                // other forms
                // check if change was made
                let inputValue = (typeof value === 'object' && value !== null) ? JSON.stringify(value) : value
                let inputOldValue = (typeof oldValue === 'object' && oldValue !== null) ? JSON.stringify(oldValue) : oldValue
                if (this.popup && !this.popup.changed && inputValue !== inputOldValue  && value !== undefined && !this.obj.attributes.ignoreChanges) {
                    if (event.hasOwnProperty('guid') && event.guid === oldValue) {
                        return
                    }
                    let payload = {
                        key: this.popup.key,
                        data: {
                            changed: true,
                            formTab: undefined
                        }
                    }
                    if (this.obj.attributes.targetTab) {
                        payload.data.formTab = this.obj.attributes.targetTab
                        payload.data.formTabKey = this.obj.key
                    }
                    this.$store.commit('setPopup', payload)
                }
            },
            checkBudget (detail, amount) {
                let basketRemainder = this.pharmacyFreeProductBudget.attributes.content - (this.basket.prices ? this.basket.prices : 0)
                let cost = detail.obj.value * amount
                let equalToBasket = false
                let productGuid = detail.obj.productGuid ? detail.obj.productGuid : detail.obj.guid
                //check for basket units
                if (this.basket && this.basket.products) {
                    let product = this.basket.products.find(item => item.productGuid === productGuid)
                    if(product){
                        cost -= detail.obj.value * product.units
                        if(product.units == amount){
                            equalToBasket = true
                        }
                    }
                }
                let remainder = basketRemainder - cost
                //console.log("Budget check (basketRemainder|cost|remainder):", basketRemainder, cost, remainder)
                if(remainder < 0 && !equalToBasket){
                    this.$set(detail, 'overBudget', true)
                } else {
                    this.$set(detail, 'overBudget', false)
                }
            },
            addToBasket (product) {
                if(product) {
                    this.getProductDetails(product)
                }
                //check units
                console.log('product units: ', parseFloat(this.product.units))
                if (parseFloat(this.product.units) <= 0) {
                    this.productAmountError = true
                    return
                } else {
                    this.productAmountError = false
                }
                //add to basket
                let isInBasket = (this.basket && this.basket.products && this.basket.products.find(item => item.productGuid === this.product.productGuid)) ? true : false
                if(!isInBasket) {
                    this.product.productGuid = this.product.guid
                    let basketProducts = this.basket.products ? this.basket.products : []
                    basketProducts.push(this.product)
                    console.log('basket', this.basket)
                    this.$set(this.basket, 'orderLinesCount', this.basket.orderLinesCount ? this.basket.orderLinesCount++ : 1)
                    this.$set(this.basket, 'products', basketProducts)
                    this.updateBasketPrices()
                    console.log('basket update', this.basket)
                    this.popup.changed = true
                }
            },
            updateBasketPrices(){
                let total = 0
                for(let i in this.basket.products){
                    total += this.basket.products[i].value * this.basket.products[i].units
                }
                this.$set(this.basket, 'prices', total)
            },
            updateContent (data) {
                this.basket = data.basket
                if (data.page) this.$store.commit('replaceTarget', data)
            },
            setBasketDiscount (modalId) {
                let data = {
                    url: 'wizard/order/discount/global',
                    payload: {
                        discount: this.modalDiscount,
                        baseDiscount: this.baseDiscount,
                        guid: this.obj.attributes.freeOrderGuid,
                        sentDate: new Date().toISOString()
                    }
                }
                this.post(data).then((response) => {
                    this.closeModal(modalId)
                    console.log('set discount response', response.data)
                    this.updateContent(response.data)
                })
            },
            showModal (id) {
                this.$root.$emit('bv::show::modal', id)
            },
            closeModal (id) {
                this.$root.$emit('bv::hide::modal', id)
            },
            clearFilters () {
                eventBus.$emit('clearInput' + this.inputs.filterSelect.key)
                eventBus.$emit('clearInput' + this.inputs.pharmacySearch.key)
                eventBus.$emit('clearInput' + this.inputs.repSearch.key)
                eventBus.$emit('clearInput' + this.inputs.freeProductSearch.key)
            },
            setBaseDiscount (val) {
                let data = {
                    url: 'wizard/order/discount/base',
                    payload: {
                        discount: val,
                        guid: this.obj.attributes.freeOrderGuid,
                        sentDate: new Date().toISOString()
                    }
                }
                this.post(data).then((response) => {
                    this.updateContent(response.data)
                })
            },
            isInBasket (guid) {
                if (this.basket.products) {
                    return this.basket.products.find(item => item.productGuid === guid)
                }
            },
            submitForm (options) {
                this.$refs['observer' + this.obj.key].validate().then(validated => {
                    if (validated) {
                        let lines = []
                        if (this.basket.products && this.basket.products.length > 0) {
                            for (let i = 0; i < this.basket.products.length; i++) {
                                lines.push({
                                    "guid": this.basket.products[i].productGuid,
                                    "value": this.basket.products[i].units
                                })
                            }
                        }
                        console.log('productlines', lines)
                        let data = {
                            url: options.button.actions.find(action => action.method === 'POST').url.substring(1),
                            payload: {
                                pharmacySearch: this.inputs.pharmacySearch.attributes.value,
                                repSearch: this.inputs.repSearch.attributes.value,
                                productLines: lines
                            }
                        }
                        this.post(data).then((response) => {
                            console.log('response', response)
                            //if (response.data) this.$store.commit('setMainJSON', response.data.page)
                            // new popup in response
                            let closeOnSave = options.button.attributes.closeOnSave
                            let obj = options.button
                            let newPopup = response.data.popup ? response.data.popup : null
                            console.log('response popup', newPopup)
                            if (newPopup) {
                                let payloadPopup = {
                                    content: newPopup,
                                    isOpen: true,
                                    key: newPopup.key
                                }
                                this.$store.commit('addPopup', payloadPopup)
                            }

                            // page in response
                            let page = response.data.page ? response.data.page : response.data
                            if (response.data.sessionId) {
                                this.$store.dispatch('saveLogin', page.sessionId)
                                return page
                            } else if (response.data.errorMessage) {
                                return page
                            } else if (page.redirect) {
                                if (page.redirectImmediately) {
                                    router.push({path: page.redirect})
                                } else {
                                    this.$store.dispatch('redirect', {url: page.redirect, method: 'GET'})
                                }
                            } else if (page && Array.isArray(page)) {
                                for (let item of page) {
                                    if (item.type) {
                                        this.$store.dispatch('processResponse', {
                                            response: item,
                                            obj: obj,
                                            key: item.key
                                        })
                                    }
                                }
                                return page
                            } else if (page && page.type) {
                                this.$store.dispatch('processResponse', {
                                    response: page,
                                    obj: obj,
                                    key: page.key
                                })
                                return page
                            } else if (response.data.redirect) {
                                if (page.redirectImmediately) {
                                    router.push({path: page.redirect})
                                } else {
                                    let action = {
                                        method: 'GET',
                                        url: response.data.redirect
                                    }
                                    this.$store.dispatch('redirect', action)
                                }
                            }

                            if (!response?.data?.formError) {
                                if (closeOnSave) {
                                    this.$store.commit('closePopupOnSave', content.actionKey)
                                }

                                if (response && response.data.toast) {
                                    this.$store.dispatch('setToast', response.data.toast)
                                }
                            }
                        })
                    } else {
                        this.notifications = Object.values(this.$refs['observer' + this.obj.key].ctx.errors).flat()
                        eventBus.$emit('error-validation', this.notifications)
                    }
                })
            },
            roundPrices(nr){
                return nr.toFixed(2)
            }
        },
        watch: {
            pharmacySearch (val) {
                if (val !== undefined && val !== 0 && val && !this.filtering) {
                    let payload = {
                        searchString: val
                    }
                    if (this.productFilterSelect) payload.filter = this.productFilterSelect
                    this.searchPharmacies(payload)
                    this.pharmacySelected = true
                } else {
                    this.pharmacySelected = false
                }
            },
            repSearch (val) {
                if (val !== undefined && val !== 0 && val && !this.filtering) {
                    let payload = {
                        searchString: val
                    }
                    if (this.productFilterSelect) payload.filter = this.productFilterSelect
                    this.searchReps(payload)
                }
            },
            freeProductSearch (val) {
                if (val !== undefined && val !== 0 && val && !this.filtering) {
                    let payload = {
                        searchString: val
                    }
                    if (this.productFilterSelect) payload.filter = this.productFilterSelect
                    this.searchFreeProducts(payload)
                }
            },
            productFilterSelect (val) {
                if (val !== '' && val !== undefined && val !== 0) {
                    let payload = {
                        filter: val
                    }
                    if (this.pharmacySearch) payload.searchString = this.pharmacySearch
                    this.searchPharmacies(payload)
                }
            },
            product: {
                deep: true,
                handler (val) {
                    if (val) {
                        this.productDiscount = parseFloat(val.discount)
                        this.productAmount = parseFloat(val.units)
                    } else {
                        this.productDiscount = 0
                        this.productAmount = 0
                    }
                }
            },
            'basket.products': function (products){
                this.basket.prices = 0
                if(products && products.length > 0){
                    for(let i=0; i<products.length; i++){
                        this.basket.prices += products[i].value * products[i].units
                    }
                }
            },
        },
        created () {
            this.searchPharmacies = debounce(this.searchPharmacies, 600, { leading: false, trailing: true })
            this.searchReps = debounce(this.searchReps, 600, { leading: false, trailing: true })
            this.searchFreeProducts = debounce(this.searchFreeProducts, 600, { leading: false, trailing: true })
            if (this.obj.attributes.productGroups) this.inputs.filterSelect.attributes.content = this.obj.attributes.productGroups[0]
            if (this.obj.attributes.basket) this.basket = this.obj.attributes.basket
            if (this.obj.attributes.currency) this.currency = this.obj.attributes.currency
            if (this.obj.attributes.showRepSearch) this.showRepSearch = this.obj.attributes.showRepSearch
        },
        mounted () {
            eventBus.$on('inputEmit' + this.obj.key, this.handleInput)
            eventBus.$on('submitForm' + this.obj.key, options => {
                this.submitForm(options)
            })
        },
        beforeDestroy () {
            eventBus.$off('inputEmit' + this.obj.key, this.handleInput)
            eventBus.$off('submitForm' + this.obj.key)
        }
    }
</script>

<style scoped lang="scss">
    .filter-row {
        border: 1px solid $light-grey;
        padding: 0.75rem 0;
        background: $ultra-light-grey;
    }

    .order-products {
        display: flex;
        flex-flow: row wrap;
        height: calc(100vh - 312px);
        max-height: calc(100vh - 312px);
        overflow: hidden;
        border-bottom: 1px solid $medium-light-grey;

        > div[class*="col-"] {
            height: 100%;
            max-height: 100%;
            overflow: auto;
        }
    }

    .results, .details {
        border-right: 1px solid $light-grey;
    }

    .details {
        padding-top: 5px;
    }

    .basket {
        position: relative;

        &__price {
            position: sticky;
            bottom: 0;
            background: $white;
        }
    }

    .placeholder,
    .warning {
        color: $medium-grey;
        margin-top: 5px;
    }

    .warning {
        color: $red;
    }
</style>