<template>
  <div :class="['popup', {fullscreen: fullscreen}]" @keyup.esc="closePopup" :ref="'popup' + index" tabindex="-1">
    <vue-draggable-resizable
        class="popupContent"
        :drag-handle="'.drag-handle'"
        :parent="false"
        :resizable="true"
        :draggable="isDraggable"
        :enable-native-drag="true"
        :w="popupWidth"
        :h="popupHeight"
        :x="left"
        :y="top"
        :key="popupSettings.content.key"
        :onDragStart="onDragStartHandler"
    >
      <component v-for="child in popupSettings.content.children"
                 :is="child.type"
                 :key="child.key"
                 :obj="child"
                 :popupFullscreen="fullscreen || popupSettings.content.attributes.fullscreen"
                 @closePopup="closePopup"
                 @toggleFullscreen="toggleFullscreen"
                 :isDraggable="isDraggable"
                 :resizable="!popupSettings.content.attributes.fullscreen"
                 :showSidebar="popupSettings.content.attributes.showSidebar"
      ></component>
    </vue-draggable-resizable>
  </div>
</template>

<script>
  import VueDraggableResizable from 'vue-draggable-resizable'
  import { mapState } from 'vuex'
  import { eventBus } from '../services/event-bus'
  import confirm from '@/mixin/confirm'

  export default {
    name: 'LhPopup',
    mixins: [confirm],
    components: {
      VueDraggableResizable
    },
    props: {
      obj: {
        type: Object
      },
      type: {
        type: String
      },
      popupSettings: {
        type: Object
      },
      index: {
        type: Number
      }
    },
    data () {
      return {
        minWidth: 700,
        minHeight: 900,
        maxHeight: 1400,
        fullscreenWidth: 1024,
        teller: 0,
        fullscreen: false
      }
    },
    computed: {
      ...mapState({
        mobile: 'mobile',
        windowSize: 'windowSize',
        popups: 'popups',
        sidebarWidth: 'sidebarWidth'
      }),
      isFullscreen () {
        return this.windowSize.windowWidth <= this.fullscreenWidth
      },
      top () {
        if (this.popupHeight > this.windowSize.windowHeight) {
          return 0
        }
        if (this.popupSettings.content.attributes.fullscreen || this.fullscreen) {
          return 0
        } else if (this.popupSettings.content.attributes.top !== undefined
          && this.popupSettings.content.attributes.top !== null) {
          return this.isFullscreen ? 0 : this.popupSettings.content.attributes.top
        } else {
          return this.isFullscreen ? 0 : this.windowSize.windowHeight > this.maxHeight
            ? this.windowSize.windowHeight/2 - this.popupHeight / 2
            : 50 + (25 * this.index)
        }
      },
      left () {
        if (this.popupWidth > this.windowSize.windowWidth) {
          return 0
        }
        if (this.popupSettings.content.attributes.fullscreen || this.fullscreen) {
          return this.popupSettings.content.attributes.showSidebar ? 0 + this.sidebarWidth : 0
        } if (this.popupSettings.content.attributes.left !== undefined
          && this.popupSettings.content.attributes.left !== null) {
          return this.isFullscreen ? 0 : this.popupSettings.content.attributes.left
        } else {
          return this.isFullscreen ? 0 : (this.windowSize.windowWidth - this.popupWidth) / 2 + (25 * this.index)
        }
      },
      popupWidth () {
        if (this.popupSettings.content.attributes.fullscreen || this.fullscreen) {
          return this.popupSettings.content.attributes.showSidebar
            ? this.windowSize.windowWidth - this.sidebarWidth
            : this.windowSize.windowWidth
        } else if (this.popupSettings.content.attributes.width) {
          return this.isFullscreen ? this.windowSize.windowWidth : this.popupSettings.content.attributes.width
        } else {
          return this.isFullscreen ? this.windowSize.windowWidth : this.minWidth
        }
      },
      popupHeight () {
        if (this.popupSettings.content.attributes.fullscreen || this.fullscreen) {
          return this.windowSize.windowHeight
        } else if (this.popupSettings.content.attributes.height) {
          return this.isFullscreen ? this.windowSize.windowHeight : this.popupSettings.content.attributes.height
        } else {
          return this.isFullscreen ? this.windowSize.windowHeight : this.windowSize.windowHeight > this.maxHeight
            ? this.windowSize.windowHeight * (2/3)
            : this.windowSize.windowHeight - 100
        }
      },
      isDraggable () {
        return !this.isFullscreen && !this.fullscreen && !this.popupSettings.content.attributes.fullscreen
      }
    },
    methods: {
      closePopup () {
        //TODO prompt from content attributes
        if (this.popupSettings.changed) {
          let confirmMsg = {
            content: 'GENERAL_CLOSEPOPUP_CONTENT',
            title: 'GENERAL_CLOSEPOPUP_TITLE',
            cancel: 'GENERAL_CLOSEPOPUP_CANCEL',
            confirm: 'GENERAL_CLOSEPOPUP_CONFIRM'
          }
          this.confirm(confirmMsg).then(value => {
            if (value) {
              if (this.popups.length - 1 > 0 && this.popups[this.popups.length - 2]) {
                //if there is popup after closing this one, send key
                eventBus.$emit('focus-popup', this.popups[this.popups.length - 2].key)
              }
              this.$store.commit('removePopup', this.popupSettings.key)
            }
          })
        } else {
          if (this.popups.length - 1 > 0 && this.popups[this.popups.length - 2]) {
            //if there is popup after closing this one, send key
            eventBus.$emit('focus-popup', this.popups[this.popups.length - 2].key)
          }
          this.$store.commit('removePopup', this.popupSettings.key)
        }
      },
      handleFocusPopup (key) {
        if (key === this.popupSettings.key && this.$refs['popup' + this.index]) {
          this.$refs['popup' + this.index].focus()
        }
      },
      handleClosePopup (key) {
        console.log(key)
        if (this.popupSettings.key === key) {
          this.closePopup()
        }
      },
      onDragStartHandler (e) {
        if (e.target.classList.contains('drag-handle')) {
          e.preventDefault()
          e.stopPropagation()
        }
      },
      toggleFullscreen () {
        this.fullscreen = !this.fullscreen
      }
    },
    mounted () {
      this.$refs['popup' + this.index].focus()
      eventBus.$on('focus-popup', this.handleFocusPopup)
      eventBus.$on('close-popup' + this.popupSettings.key, this.closePopup)
      if (this.popupSettings.content.attributes.fullscreen) this.fullscreen = true
    },
    destroyed () {
      eventBus.$off('focus-popup', this.handleFocusPopup)
      eventBus.$off('close-popup' + this.popupSettings.key, this.closePopup)
    }
  }
</script>

<style lang="scss">
  // overlay
  .popup {
    position: fixed;
    display: block;
    background: #00000030;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 300;

    @include media-breakpoint-up(md) {
      position: absolute;
    }

    &.fullscreen {
      position: fixed !important;
    }
  }

  // wrapper
  .popupContent {
    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-flow: column nowrap;
    overflow-y: hidden;
    background-color: $popup-bg;
    box-shadow: $popup-shadow;

    @include media-breakpoint-up(md) {
      top: $popup-top;
      width: $popup-width;
    }
  }
</style>
