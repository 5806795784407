import Vue from 'vue'
import { findTarget, replaceTargetObj } from '../js/find-target'


const mutations = {
  setMainJSON (state, mainJSON) {
    //state.mainJSON = mainJSON
    Vue.set(state, 'mainJSON', mainJSON)
  },
  setMainJSONLocalStorage (state, payload) {
    localStorage.setItem(payload.link, payload.mainJSON)
  },
  setToken (state, token) {
    state.token = token
  },
  setAuthJSON (state, authJSON) {
    state.authJSON = authJSON
  },
  setDownloading (state, payload) {
    state.downloading = payload
  },
  setUser (state, user) {
    state.user = user
  },
  destroyToken (state) {
    state.token = null
  },
  setPageLoaded (state, response) {
    state.pageLoaded = response.data
  },
  setLoadingOrError (state, payload) {
    state.loadingOrError = payload
  },
  setClose (state, payload) {
    state.WindowOpen = payload
  },
  clearAll (state) {

  },
  addPopup (state, payload) {
    let popup = state.popups.find(item => item.key === payload.key)
    if (popup) {
      let index = state.popups.indexOf(popup)
      state.popups[index] = payload
    } else {
      state.popups.push(payload)
    }
  },
  removePopup (state, popupKey) {
    let array = state.popups
    let popup = array.find(item => item.key === popupKey)

    let index = array.indexOf(popup)
    if (index > -1) {
      array.splice(index, 1)
    }
    // state.popups.splice(index, 1);
  },
  removePopupOnTop (state) {
    state.popups.pop()
  },
  setPopup (state, payload) {
    //update popup
    let data = payload.data
    let key = payload.key
    let popup = state.popups.find(item => item.key === key)

    if (popup) {
      let index = state.popups.indexOf(popup)
      if (data.content) {
        state.popups[index].content = data.content
      }
      if (typeof data.isOpen != 'undefined') {
        state.popups[index].isOpen = data.isOpen
      }
      if (data.hasOwnProperty('changed')) {
        state.popups[index].changed = data.changed
      }
      if (data.hasOwnProperty('formTab')) {
        state.popups[index].formTab = data.formTab
      }
      if (data.hasOwnProperty('formTabKey')) {
        state.popups[index].formTabKey = data.formTabKey
      }
    }
  },
  closePopupOnSave (state, payload) {
    for (let popup of state.popups) {
      let element = findTarget(popup.content, payload)
      if (element.path.length > 0) {
        let index = state.popups.indexOf(popup)
        if (index > -1) {
          state.popups.splice(index, 1)
        }
      }
    }
  },
  closeAllPopup (state) {
    state.popups = []
  },
  addBrowseSetting (state, payload) {
    state.browseSettings.push(payload)
  },
  removeBrowseSetting (state, browseKey) {
    let array = state.browseSettings
    let browseSetting = array.find(item => item.key === browseKey)
    let filterData = state.filterData.find(filter => filter.key === browseKey)

    console.log('remove browse', browseSetting, '& remove filter: ', filterData)
    let index = array.indexOf(browseSetting)
    if (index > -1) {
      array.splice(index, 1)
    }
    let filterIndex = state.filterData.indexOf(filterData)
    if (filterIndex > -1) {
      state.filterData.splice(filterIndex, 1)
    }
    let aFilterIndex = state.activeFilterData.findIndex(item => item.key == browseKey)
    if (aFilterIndex > -1) {
      state.activeFilterData.splice(aFilterIndex, 1)
    }
  },
  setFormItems (state, payload) {
    state.formItems = payload
  },
  setBrowseSettings (state, payload) {
    if (payload.viewType) {
      state.browseSettings.viewType = payload.viewType
    }
    if (payload.headers) {
      state.browseSettings.headers = payload.headers
    }
    if (payload.checkboxHeaders) {
      state.browseSettings.checkboxHeaders = payload.checkboxHeaders
    }
    if (payload.selectedRow) {
      state.browseSettings.selectedRow = payload.selectedRow
    }
    if (payload.hasOwnProperty('selectionLength')) {
      state.browseSettings.selectionLength = payload.selectionLength
    }
    if (payload.content) {
      if (payload.edit) {
        let selectedRow = state.browseSettings.selectedRow
        for (const item in payload.content) {
          if (payload.content.hasOwnProperty(item)) {
            state.browseSettings.content[selectedRow][item] =
                payload.content[item]
          }
        }
      } else {
        state.browseSettings.content = payload.content
      }
    }
  },
  setBrowseSettingByKey (state, data) {
    let key = data.key
    let payload = data.payload
    let browse = state.browseSettings.find(item => item.key === key)

    if (browse && payload) {
      for (let propName in payload) {
        Vue.set(browse, propName, payload[propName])
      }
    } else {
      //  add browseSetting
      state.browseSettings.push(data)
    }
  },
  removeSelectedRows (state, data) {
    let browses = state.browseSettings
    browses.forEach(browse => {
      browse.selectedRows = []
      browse.selectionLength = 0
    })
  },
  setHeaderWidthByKey (state, data) {
    let key = data.key
    let width = data.width
    let target = data.target
    let browse = state.browseSettings.find(item => item.key === key)

    if (browse) {
      browse.headersWidth[target] = width
    }
  },
  setViewType (state, payload) {
    state.viewType = payload
  },
  setSideFormContent (state, payload) {
    state.sideFormContent = payload
  },
  removeSideform (state) {
    state.sideFormContent = {}
  },
  setDetailPage (state, payload) {
    state.detailPage = payload
  },
  setHtmlPage (state, payload) {
    state.htmlPage = payload
  },
  setFilterDataByKey (state, data) {
    let key = data.key
    let filter = state.filterData.find(item => item.key === key)
    if (filter) {
      filter.inputs = {}
      if (data.inputs) {
        for (let inputsKey in data.inputs) {
          filter.inputs[inputsKey] = data.inputs[inputsKey]
        }
      }
      if (data.defaultFilters) {
        for (let key in data.defaultFilters) {
          filter.defaultFilters[key] = data.defaultFilters[key]
        }
      }
    } else {
      state.filterData.push(data)
    }
  },
  setActiveFilterData (state, key) {
    // let activeData = state.activeFilterData.find(item => item.key == key)
    // if (activeData) {
    //   let data = state.filterData.find(item => item.key == key)
    //   if (data.inputs) {
    //     for (let inputsKey in data.inputs) {
    //       activeData.inputs[inputsKey] = data.inputs[inputsKey]
    //     }
    //   }
    //   if (data.defaultFilters) {
    //     for (let key in data.defaultFilters) {
    //       activeData.defaultFilters[key] = data.defaultFilters[key]
    //     }
    //   }
    // } else {
    //   let browseFilterData = state.filterData.find(item => item.key == key)
    //   state.activeFilterData.push(browseFilterData)
    // }
    let browseFilterData = state.filterData.find(item => item.key == key)
    if (browseFilterData) {
      state.activeFilterData = []
      for (let key in state.filterData)  {
        let submittedFilterData = JSON.parse(JSON.stringify(state.filterData[key]))
        Vue.set(state.activeFilterData, key, submittedFilterData)
        // state.activeFilterData = state.filterData
      }
    }
  },
  removeAllFilterData (state) {
    console.log("Remove filters")
    Vue.set(state, 'filterData', [])
    Vue.set(state, 'activeFilterData', [])
  },
  setFormFilledDataByKey (state, data) {
    let key = data.key
    let form = state.formFilledData.find(item => item.key === key)
    if (form) {
      if (data.inputs) {
        for (let inputsKey in data.inputs) {
          form.inputs[inputsKey] = data.inputs[inputsKey]
        }
      }
    } else {
      state.formFilledData.push(data)
    }
  },
  clearFormFilledData (state, formKey) {
    let array = state.formFilledData
    let form = array.find(item => item.key === formKey)

    for (let input in form.inputs) {
      Vue.set(form.inputs, input, '')
      // form.inputs[input] = ''
    }
  },
  removeFormFilledData (state, formKey) {
    let array = state.formFilledData
    let form = array.find(item => item.key === formKey)

    let index = array.indexOf(form)
    if (index > -1) {
      array.splice(index, 1)
    }
  },
  setPaginationData (state, data) {
    let key = data.key
    let pagination = state.paginationData.find(item => item.key === key)

    if (pagination) {
      if (pagination.currentPage && data.currentPage) {
        pagination.currentPage = data.currentPage
      }
      if (data?.payload?.browseSize) pagination.browseSize = data.payload.browseSize
    } else {
      state.paginationData.push(data)
    }
  },
  setSortData (state, payload) {
    state.sortData = payload
  },
  removeSortData (state) {
    if (state.browseSettings[0]) {
      state.browseSettings[0].headers.forEach(header => {
        header.sort = 0
        header.sorting = false
      })
    }
  },
  toggleSidebar (state) {
    state.sidebarCollapse = !state.sidebarCollapse
  },
  setLoading (state) {
    state.loadingJSON = true
  },
  stopLoading (state) {
    state.loadingJSON = false
  },
  replaceTarget (state, payload) {
    // if target is inside popup -> payload has popup
    let popup = payload.popup

    //page contains the response from server -> has content & key
    let page = payload.page

    //targetObj is the obj that is going to be replaced
    let targetObj = {}
    if (popup && popup.content) {
      targetObj = popup.content
    } else {
      targetObj = state.mainJSON
    }

    //if these are present -> not a component being replaced
    if (['attributes', 'styles', 'actions'].includes(payload.type)) {
      page = {
        key: payload.target
      }
    } else if (!page) {
      page = {
        key: ''
      }
    }

    //function that replaces target after it is found
    let replace = function (toChange, target) {
      console.log('replacing', target)
      console.log(toChange)
      let element = toChange
      for (let index = 0; index < target.path.length - 1; index++) {
        const path = target.path[index]
        element = element.children[path]
      }
      if (payload.type == "attributes") {
        for (let attr in payload.content){
          Vue.set(
            element.children[target.path[target.path.length - 1]].attributes,
            attr,
            payload.content[attr]
          )
        }
      } else if (payload.type == "styles") {
        if (Array.isArray(element.children[target.path[target.path.length - 1]].styles)) {
          Vue.set(
            element.children[target.path[target.path.length - 1]],
            'styles',
            payload.styles + ":" + payload.content
          )
        } else {
          for (let style in payload.content){
            Vue.set(
              element.children[target.path[target.path.length - 1]].styles,
              style,
              payload.content[style]
            )
          }
        }
      } else if (payload.type == "actions") {
        for (let action in payload.content){
          Vue.set(
            element.children[target.path[target.path.length - 1]].actions,
            action,
            payload.content[action]
          )
        }
      } else {
        Vue.set(
          element.children,
          target.path[target.path.length - 1],
          page
        )
      }
    }

    //check if popup can be replace whole
    for (let popupState of state.popups) {
      if (popupState.key == page.key) {
        Vue.set(
          popupState,
          'content',
          page
        )
      }
    }

    //continue to replace in children of target
    let target = findTarget(targetObj, page.key)
    console.log(target)
    let found = false
    if (target && target.path.length > 0) {
      //start replacing in main or a popup
      found = true
      replace(targetObj, target)
    }
    if (!found && popup.content) {
      //if not first target (popup) search in mainJSON
      let targetObjBackup = state.mainJSON

      let targetBackup = findTarget(targetObjBackup, page.key)
      console.log(targetBackup)

      if (targetBackup && targetBackup.path.length > 0) {
        found = true
        replace(targetObjBackup, targetBackup)
      } else {
        //still not found, search in other popups
        for (let popupState of state.popups) {
          if (popupState.key !== popup.key) {
            let targetBackup = findTarget(popupState, page.key)
            console.log(targetBackup)

            if (targetBackup && targetBackup.path.length > 0) {
              found = true
              replace(popupState.content, targetBackup)
            }
          }
        }
      }
    }
    if (!found){
      // not found in mainJSON, search all popups
      for (let popupState of state.popups) {
        let targetBackup = findTarget(popupState.content, page.key)
        if (targetBackup && targetBackup.path.length > 0) {
          replace(popupState.content, targetBackup)
        }
      }
    }
  },
  updateTranslations (state, payload) {
    let newTranslations = payload.translations
    let newLocale = payload.locale
    let translations = state.translations
    if (newTranslations) state.latestTranslations = { ...newTranslations }

    if (translations == {}) {
      state.translations = newTranslations
    } else {
      for (let locale in newTranslations) {
        if (translations[locale]) {
          // translations[locale] = Object.assign({}, newTranslations[locale])
          for (let key in newTranslations[locale]) {
            translations[locale][key] = newTranslations[locale][key]
          }
        } else {
          translations[locale] = newTranslations[locale]
        }
      }
    }
    if (newLocale) state.localeStore = newLocale
  },
  toggleTranslations (state) {
    this.state.translationEditMode = !this.state.translationEditMode
  },
  removeLastRoutes (state, route) {
    if (state.lastRoutes.length < 2 || route == []) {
      state.lastRoutes = []
    } else {
      state.lastRoutes = state.lastRoutes.filter(r => r !== route)
    }
  },
  setMobile (state, width) {
    state.isMobile = width < 768
  },
  setWindowSize (state, windowSize) {
    state.windowSize = windowSize
  },
  setRedirectCheck (state, check) {
    state.redirectCheck = check
  },
  setRedirectCheckApi (state, check) {
    state.redirectCheckApi = check
  },
  setBackendVersion (state, version) {
    state.backendVersion = version
  },
  setSidebar (state, sidebar) {
    state.sidebarJSON = sidebar
  },
  setLastRoutes (state, route) {
    state.lastRoutes.push(route)
  },
  setSidebarWidth (state, width) {
    state.sidebarWidth = width
  },
  toggleDownscaleUi (state) {
    state.downscaleUI = !state.downscaleUI
  },
  setActiveTab (state, tab) {
    state.activeTab = tab
  }
}

export default mutations
