<template>
  <div id="app" :class="[{'block-scroll': !collapsed}]">
    <template>
      <small>{{$store.getters.getDownloading}}</small>
      <div :class="['menu-toggle', 'd-md-none', {collapsed: collapsed}]" v-if="sidebarPresent" @click="handleToggle">
        <font-awesome-icon :icon="['fas', 'bars']"></font-awesome-icon>
      </div>
      <!--    loading image-->
<!--      <b-container v-if="loading && noJson" :class="['loading', 'loading-wrapper']">-->
<!--&lt;!&ndash;        <b-row align-v="center" align-h="center">&ndash;&gt;-->
<!--&lt;!&ndash;          <b-col cols="auto">&ndash;&gt;-->
<!--&lt;!&ndash;            <img src="~@/assets/img/logo.svg"/>&ndash;&gt;-->
<!--&lt;!&ndash;          </b-col>&ndash;&gt;-->
<!--&lt;!&ndash;        </b-row>&ndash;&gt;-->
<!--        <b-row align-v="center" align-h="center">-->
<!--          <b-col cols="auto" class="spinner">-->
<!--            <font-awesome-icon :icon="['fad', 'spinner']" spin></font-awesome-icon>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </b-container>-->
      <router-view></router-view>
      <lh-popup
          v-for="(popup, index) in popups"
          :popupSettings="popup"
          :index="index"
          :key="'popup'+index"
      ></lh-popup>
    </template>
  </div>
</template>

<script>
  import LanguageSelector from './components/LhLanguageSelector'
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
  import { eventBus } from './services/event-bus'
  import { findTarget } from './js/find-target'

  export default {
    components: { LanguageSelector },
    data () {
      return {
        windowScrollPosition: 0
      }
    },
    computed: {
      ...mapState({
        collapsed: 'sidebarCollapse',
        loading: 'loadingJSON',
        version: 'version',
        lang: 'translations',
        downscaleUI: 'downscaleUI',
        isMobile: 'isMobile'
      }),
      ...mapGetters({
        popups: 'getPopups',
        htmlPage: 'getHtmlPage',
        mainJSON: 'getMainJSON'
      }),
      noJson () {
        let paths = ['/reset-password']
        return !this.notEmpty(this.mainJSON) && !paths.includes(this.$route.path)
      },
      sidebarPresent () {
        let sidebar = findTarget(this.mainJSON, 'Root_Sidebar')
        return sidebar.target.key
      }
    },
    methods: {
      ...mapMutations({
        toggleSidebar: 'toggleSidebar',
        toggleTranslations: 'toggleTranslations'
      }),
      ...mapActions({
        updateTranslations: 'updateTranslations'
      }),
      handleToggle () {
        let scrollable = document.querySelector('.mainPage')
        if (this.collapsed) {
          // do this when opening the sidebar
          this. windowScrollPosition = window.pageYOffset
          // scrollable.style.top = - this.windowScrollPosition + 'px';
        } else {
          // do this when closing the sidebar
          window.scrollTo(0, this.windowScrollPosition)
          // scrollable.style.top = 0
        }
        this.toggleSidebar()
      },
      translate (val) {
        this.updateTranslations(val)
        this.$i18n.locale = val.locale
      },
      handleError (data) {
        let message = ''
        let title = ''
        if (data.error) {
          message = data.error.message
          title = data.error.title
        } else {
          title = 'Er is een fout opgetreden'
          message = 'Er ging iets mis!'
        }

        let payload = {
          translations: data.translations,
          locale: data.options['active_language']
        }
        this.updateTranslations(payload)

        this.$root.$bvToast.toast(this.$t(message), {
          title: this.$t(title),
          noAutoHide: true,
          variant: 'danger',
          toaster: this.isMobile ? 'b-toaster-bottom-center' : 'b-toaster-top-right'
        })
      },
      handleShowToast (data) {
        const h = this.$createElement
        let vNodesMsg = h('p', this.$t(data.message))
        let variant = data.variant ? data.variant : 'success'
        let noAutoHide = data.noAutoHide ? data.noAutoHide : false

        this.$root.$bvToast.toast(vNodesMsg, {
          title: this.$t(data.title),
          noAutoHide: noAutoHide,
          variant: variant,
          toaster: this.isMobile ? 'b-toaster-bottom-center' : 'b-toaster-top-right'
        })
      },
      handleErrorValidation (data) {
        const h = this.$createElement
        let vNodesMsg = []
        let checkDoubleLines = []

        for (let line of data) {
          if (!checkDoubleLines.includes(line)) {
            vNodesMsg.push(h('p', line))
            checkDoubleLines.push(line)
          }
        }

        let title = 'GENERAL_VALIDATION_ERROR_TITLE'

        this.$root.$bvToast.toast(vNodesMsg, {
          title: this.$t(title),
          noAutoHide: false,
          variant: 'default',
          toaster: this.isMobile ? 'b-toaster-bottom-center' : 'b-toaster-top-right'
        })
      },
      handleToggleTranslations () {
        this.toggleTranslations()
      },
      setUIScale () {
        if (this.downscaleUI && !this.isMobile) {
          document.documentElement.classList.add('downscale-ui')
        }
      }
    },
    mounted () {
      console.log(`%cVersion ${this.version}`, 'font-size: 20px; color: white; background: black;')
      eventBus.$on('error-message', this.handleError)
      eventBus.$on('error-validation', this.handleErrorValidation)
      eventBus.$on('translate', this.translate)
      eventBus.$on('show-toast', this.handleShowToast)
      eventBus.$on('translations-toggle-view', this.handleToggleTranslations)
      this.setUIScale()
    },
    watch: {
      isMobile () {
        if (this.isMobile && !this.collapsed) {
          this.toggleSidebar()
        }
        if (this.isMobile) {
          document.documentElement.classList.remove('downscale-ui')
        }
      },
      downscaleUI (val) {
        if (val && !document.documentElement.classList.contains('downscale-ui')) document.documentElement.classList.add('downscale-ui')
        if (!val) document.documentElement.classList.remove('downscale-ui')
      }
    },
    beforeDestroy () {
      eventBus.$off('error-message', this.handleError)
      eventBus.$off('error-validation', this.handleErrorValidation)
      eventBus.$off('translate', this.translate)
      eventBus.$off('show-toast', this.handleShowToast)
      eventBus.$off('translations-toggle-view', this.handleToggleTranslations)
    }
  }
</script>

<style lang="scss">
  @import "assets/style/global/global";
  @import "assets/style/bootstrap 3/bootstrap";

  #app {
    background-repeat: no-repeat !important;
    min-height: 100vh;
    display: flex;
    flex-flow: column wrap;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .block-scroll {
    overflow: hidden;
  }

  .menu-toggle {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0.68rem;
    right: 1rem;
    z-index: 200;
    font-size: 1.75rem;
    color: $secondary-color;
    padding: 0.5rem;
    line-height: 1;
    background: $white;
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }

  .loading {
    height: 100vh;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;

    img {
      height: 150px;
      width: 90vw;
      
    }

    .spinner {
      font-size: 2rem;
      color: $primary-color;
    }
  }

  .loading-wrapper {
    z-index: 2000;
  }

  .replaceTest{
    z-index: 3000;
  }
</style>
