import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import BasePage from './views/LhBasePage.vue'
import DetailPage from './views/LhDetailPage.vue'
import store from './store/store.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      components: { default: Home }
    },
    {
      path: '/detail-page/',
      name: 'detailPage',
      component: function () {
        return import(
          /* webpackChunkName: "LhDetailPage" */ './views/LhDetailPage.vue'
          )
      }
    },
    {
      path: '/about',
      name: 'about',
      component: function () {
        return import(/* webpackChunkName: "about" */ './views/About.vue')
      }
    },
    {
      path: '/test',
      name: 'test',
      component: function () {
        return import(
          /* webpackChunkName: "test" */ './views/deprecated/LhDownload.vue'
          )
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "login" */ './views/LhLogin.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () =>
        import(/* webpackChunkName: "login" */ './views/LhLogin.vue'),
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () =>
        import(/* webpackChunkName: "login" */ './views/LhLogin.vue'),
    },
    {
      path: '/new-password/:id',
      name: 'new-password',
      component: () =>
        import(/* webpackChunkName: "login" */ './views/LhLogin.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () =>
        import(/* webpackChunkName: "login" */ './views/LhLogout.vue'),
      props: { default: true, sidebar: false }
    },
    {
      path: '/iconlist',
      name: 'iconlist',
      component: () =>
        import(/* webpackChunkName: "login" */ './views/IconList.vue'),
      props: { default: true, sidebar: false }
    },
    {
      path: '/dev/translations',
      name: 'translations',
      component: () =>
        import(/* webpackChunkName: "login" */ './components/LhTranslations.vue'),
      props: { default: true, sidebar: true }
    },
    {
      path: '/error',
      name: 'errorPage',
      component: () =>
        import(/* webpackChunkName: "login" */ './views/GeneralError.vue'),
      props: { default: true, sidebar: false }
    },
    {
      path: '*',
      name: 'BasePage',
      component: BasePage
    }
  ]
})

export default router

router.beforeEach((to, from, next) => {
  console.log('TO:', to.fullPath, "FROM:", from.fullPath)
  let excludedRoutes = ['/login', '/logout', '/error',
    '/reset-password', '/register', '/new-password', '/iconlist', '/detail-page', '/dev/translations']
  if (process.env.NODE_ENV == 'development') {
    excludedRoutes.push(...['/home','/about'])
  }
  if (!excludedRoutes.includes(to.path) && (to.path == '/' || from.path != to.path)) {
    store.dispatch('fetchJSON', to.fullPath).then((response) => {
        console.log('fetch response: ', response)
        if (response && response.data && response.data.redirect == '/login') {
          // /login is caught in axios interceptor
          return
        }else  {
          next()
        }
    })
      .catch(error => {
        next({path: '/error'})
        console.log(error)
      })
  } else if (excludedRoutes.includes(to.path)) {
    next()
  } else if(to.path != '/' && from.path == to.path) {
    next()
  }

  // // redirect to login page if not logged in and trying to access a restricted page
  // const publicPages = ['/login', '/register']
  // const authRequired = !publicPages.includes(to.path)
  // const loggedIn = localStorage.getItem('user')
  //
  // if (authRequired && !loggedIn) {
  //   return next('/login')
  // }
  //
  // next()
})
